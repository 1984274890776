import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import LogoutPage from './Logout';
import { IMAGES, SVGICON } from "../../constant/theme";
import { getLocalData } from "../../../helper/helper";
import { LOCAL_STORAGE_KEY } from "../../../services/ApiService";
import { Roles } from "../../constant/AppConstant";
import AssetsService from "../../../services/AssetsService";
import { isRoleAdmin } from "../../../services/AuthService";

const NotificationContent = ({ type }) => {
	return (
		<>
		</>
	)
}

const Header = ({ onAction }) => {
	const [headerFix, setheaderFix] = useState(false);
	const [userInfo, setUserInfo] = useState(null);
	const isAdmin = isRoleAdmin();
	const [refreshPage, setRefreshPage] = useState(false);

	const fetchUserDetails = () => {
		const userDetailsStr = getLocalData(LOCAL_STORAGE_KEY.USER_DETAILS_KEY);
		const userDetails = userDetailsStr ? JSON.parse(userDetailsStr) : null;

		if (userDetails) {
			setUserInfo(userDetails);
			getSignedLogoURL(userDetails.vendorInfo);
		}
	};

	const getLogoURL = () => {
		let fileURL = IMAGES.IconUser;

		if (userInfo && userInfo.vendorInfo) {
			fileURL = userInfo.vendorInfo.logo && userInfo.vendorInfo.logo !== '' ? userInfo.vendorInfo.logo : IMAGES.IconUser;

			// if (userInfo.vendorInfo.keyFile && userInfo.vendorInfo.keyFile.logoKeyFile) {
			// 	fileURL = AssetsService.getFileURL(userInfo.vendorInfo.keyFile.logoKeyFile);
			// }
		}

		return fileURL;
	};

	const getSignedLogoURL = async (vendorInfo) => {
		try {
			if (vendorInfo.keyFile && vendorInfo.keyFile.logoKeyFile) {
				const fileURL = AssetsService.getFileURL(vendorInfo.keyFile.logoKeyFile);

				vendorInfo.logo = await AssetsService.getSignedURL(fileURL);

				if (userInfo && userInfo.vendorInfo) {
					userInfo.vendorInfo.logo = vendorInfo.logo;
				}

				setRefreshPage(!refreshPage);
			}
		} catch (error) {
			// console.warn(error);
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", () => {
			setheaderFix(window.scrollY > 50);
		});

		fetchUserDetails();
	}, []);

	return (
		<div className={`header ${headerFix ? "" : ""} bg-white`}>
			<div className="header-content">
				<nav className="navbar navbar-expand">
					<div className="collapse navbar-collapse justify-content-between">
						<div className="header-left">
							{/* <div className="input-group search-area">
								<span className="input-group-text rounded-0">
									<Link to={"#"}>
										<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
											<circle cx="8.78605" cy="8.78605" r="8.23951" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
											<path d="M14.5168 14.9447L17.7471 18.1667" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
										</svg>
									</Link>
								</span>
								<input type="text" className="form-control rounded-0" placeholder="Search" />
							</div> */}
						</div>
						<ul className="navbar-nav header-right">
							{/* <Dropdown as="li" className="nav-item dropdown notification_dropdown">
								<Dropdown.Toggle className="nav-link i-false c-pointer" variant="" as="a">
									<img src={IMAGES.IconAlert} alt="" className="icon-menu" />
								</Dropdown.Toggle>
								<Dropdown.Menu align="end" className="mt-2 dropdown-menu dropdown-menu-end">
									<div className="widget-media dz-scroll p-3 height100">
										<ul className="timeline">
											<NotificationContent type='info' />
										</ul>
									</div>
									<Link className="all-notification" to="#">
										See all notifications <i className="ti-arrow-right" />
									</Link>
								</Dropdown.Menu>
							</Dropdown> */}
							<li className="nav-item border-start ps-1">
								<Dropdown className="header-profile2">
									<Dropdown.Toggle className="nav-link i-false" as="div">
										<div className="header-info2 d-flex align-items-center">
											<div className="header-media">
												<img src={getLogoURL()} alt="" />
											</div>
											<div className="header-info">
												<h6 className="text-black">{userInfo?.name}</h6>
												<p className="text-dark">{userInfo?.email}</p>
											</div>

										</div>
									</Dropdown.Toggle>
									<Dropdown.Menu align="end">
										<div className="card border-0 mb-0">
											<div className="card-header py-2">
												<div className="products">
													<img src={getLogoURL()} className="avatar avatar-md" alt="" />
													<div>
														<h6>{userInfo?.name}</h6>
														<span>{Roles.GetRoleName(userInfo?.roles)}</span>
													</div>
												</div>
											</div>
											<div className="card-body px-0 py-2">
												<Link to={"/my-profile"} className="dropdown-item ai-icon ">
													{SVGICON.User}{" "}
													<span className="ms-2">Profile </span>
												</Link>
												{isAdmin && <>
													<Link to={"/settings"} className="dropdown-item ai-icon ">
														{SVGICON.Headersetting} {" "}
														<span className="ms-2">Settings </span>
													</Link>
												</>}
											</div>
											<div className="card-footer px-0 py-2">
												<LogoutPage />
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</li>
						</ul>
					</div>
				</nav>
			</div>
		</div>
	);
};

export default Header;
