import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Card, Col, Nav, Offcanvas, Row, Tab } from 'react-bootstrap';
import { AllTabsVendorDetails, UserStatus, UserStatusList, VendorTabKeys } from '../../constant/AppConstant';
import AssetsService from '../../../services/AssetsService';
import { IMAGES } from '../../constant/theme';
import { showToastError } from '../../../helper/helper';
import UsersService from '../../../services/UsersService';
import AdminAccountOnlyView from './AdminAccountOnlyView';

const VendorDetails = forwardRef((props, ref) => {
    const [display, setDisplay] = useState(false);
    const [vendorDetails, setVendorDetails] = useState(null);
    const [adminAccounts, setAdminAccounts] = useState([]);
    const [refreshPage, setRefreshPage] = useState(false);

    const closeToggle = () => {
    }

    useImperativeHandle(ref, () => ({
        showDetailsModal(vendor) {
            setVendorDetails(vendor);
            setDisplay(true);
            getSignedLogoURL(vendor);
        }
    }));

    const loadAdminAccounts = async () => {
        if (vendorDetails == null || vendorDetails.vendorId == null || vendorDetails.vendorId === '')
            return;

        try {
            await UsersService.getUsersByVendorId(vendorDetails.vendorId)
                .then((response) => {
                    if (response) {
                        setAdminAccounts(response);
                    } else {
                        if (response && response.message) {
                            showToastError(response.message);
                        }
                    }
                });
        } catch (error) {
            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    const getLogoURL = () => {
        let fileURL = IMAGES.Avatar;

        if (vendorDetails) {
            fileURL = vendorDetails.logo && vendorDetails.logo !== '' ? vendorDetails.logo : IMAGES.Avatar;

            // if (vendorDetails.keyFile && vendorDetails.keyFile.logoKeyFile) {
            //     fileURL = AssetsService.getFileURL(vendorDetails.keyFile.logoKeyFile);
            // }
        }

        return fileURL;
    };

    const getSignedLogoURL = async (vendor) => {
        try {
            if (vendor.keyFile && vendor.keyFile.logoKeyFile) {
                const fileURL = AssetsService.getFileURL(vendor.keyFile.logoKeyFile);

                vendor.logo = await AssetsService.getSignedURL(fileURL);

                if (vendorDetails) {
                    vendorDetails.logo = vendor.logo;
                }

                setRefreshPage(!refreshPage);
            }
        } catch (error) {
            // console.warn(error);
        }
    };

    const getStatusLabel = () => {
        let status;

        if (vendorDetails && vendorDetails.status) {
            for (let i = 0; i < UserStatusList.length; i++) {
                if (vendorDetails.status === UserStatusList[i].key) {
                    status = UserStatusList[i];
                    break;
                }
            }
        }

        if (status) {
            return (<span className='pe-0 c-status'><i
                className=
                {`fa-solid fa-circle text-${status.key === UserStatus.ACTIVE ? 'available' :
                    status.key === UserStatus.DISABLED ? 'disabled' : 'primary'}`}
            />{status.title}</span>);
        }

        return '-';
    };

    const getStatusAllowCreateVALabel = () => {
        if (vendorDetails && vendorDetails.allowCreateAccount) {
            return 'Enable';
        }

        return 'Disabled';
    };

    return (
        <>
            <Offcanvas show={display} onHide={() => {
                closeToggle();
                setDisplay(false);
            }} className="offcanvas-end customeoff" placement='end'>
                <div className="offcanvas-header">
                    <h5 className="modal-title" id="#gridSystemModal">{props.title}: <span className='text-danger'>{vendorDetails ? vendorDetails.companyName : ''}</span></h5>
                    <button type="button" className="btn-close" onClick={() => {
                        closeToggle();
                        setDisplay(false);
                    }}><i className="fa-solid fa-xmark"></i></button>
                </div>
                <div className="offcanvas-body">
                    <div className="container-fluid">
                        <Row>
                            <Col xl={12}>
                                <Tab.Container defaultActiveKey="Preview">
                                    <Card name="default-tab" className="dz-card">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="Preview">
                                                <Card.Body>
                                                    {/* <!-- Nav tabs --> */}
                                                    <div className="default-tab">
                                                        <Tab.Container defaultActiveKey={AllTabsVendorDetails[0].key}>
                                                            <Nav as="ul" className="nav-tabs">
                                                                {AllTabsVendorDetails.map((data, i) => (
                                                                    <Nav.Item as="li" key={i}>
                                                                        <Nav.Link eventKey={data.key} onClick={(e) => { if (data.key === VendorTabKeys.ADMIN_ACCOUNTS) { loadAdminAccounts(); } }}>
                                                                            <i className={`la la-${data.icon} me-2`} />
                                                                            {data.name}
                                                                        </Nav.Link>
                                                                    </Nav.Item>
                                                                ))}
                                                            </Nav>
                                                            <Tab.Content className="pt-4">
                                                                {AllTabsVendorDetails.map((data, i) => (
                                                                    <Tab.Pane eventKey={data.key} key={i}>
                                                                        {/* Basic Information */}
                                                                        {data.key === VendorTabKeys.BASIC_INFORMATION ? (
                                                                            <div className="form-validation">
                                                                                <div className="row">
                                                                                    <div className="col-xl-12">
                                                                                        <div className="form-group mb-2 row">
                                                                                            <div className="col-lg-12">
                                                                                                <div className="dz-default dlab-message upload-img">
                                                                                                    <div className="dropzone">
                                                                                                        <div className="c-profile text-center">
                                                                                                            <img src={getLogoURL()} alt="" className="rounded-circle logo-vendor" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-4 col-form-label text-danger">Vendor ID</label>
                                                                                            <div className="col-lg-8">
                                                                                                <label className="col-form-label text-danger">{vendorDetails ? vendorDetails.vendorId : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-4 col-form-label">Company Name</label>
                                                                                            <div className="col-lg-8">
                                                                                                <label className="col-form-label text-muted" >{vendorDetails ? vendorDetails.companyName : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-4 col-form-label">Business Reg No.</label>
                                                                                            <div className="col-lg-8">
                                                                                                <label className="col-form-label text-muted" >{vendorDetails ? vendorDetails.businessRegNo : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-4 col-form-label">Contact</label>
                                                                                            <div className="col-lg-8">
                                                                                                <label className="col-form-label text-muted" >{vendorDetails && vendorDetails.contact ? vendorDetails.contact : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-1 row">
                                                                                            <label className="col-lg-4 col-form-label">Email</label>
                                                                                            <div className="col-lg-8">
                                                                                                <label className="col-form-label text-muted" >{vendorDetails && vendorDetails.email ? vendorDetails.email : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group row">
                                                                                            <label className="col-lg-4 col-form-label">Address</label>
                                                                                            <div className="col-lg-8">
                                                                                                <label className="col-form-label text-muted" >{vendorDetails && vendorDetails.address ? vendorDetails.address : '-'}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group row">
                                                                                            <label className="col-lg-4 col-form-label">Status</label>
                                                                                            <div className="col-lg-8">
                                                                                                <label className="col-form-label text-muted" >{getStatusLabel()}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group row">
                                                                                            <label className="col-lg-4 col-form-label">Allow Create Vendor Admin</label>
                                                                                            <div className="col-lg-8">
                                                                                                <label className="col-form-label text-muted" >{getStatusAllowCreateVALabel()}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ) : ''}
                                                                        {/* Admin Accounts */}
                                                                        {data.key === VendorTabKeys.ADMIN_ACCOUNTS ? (
                                                                            <div className="form-validation">
                                                                                <div className="row">
                                                                                    {adminAccounts.map((acc, i) => {
                                                                                        return (<AdminAccountOnlyView key={acc.id} index={i} data={acc} />);
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        ) : ''}
                                                                    </Tab.Pane>
                                                                ))}
                                                            </Tab.Content>
                                                        </Tab.Container>
                                                    </div>
                                                </Card.Body>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Card>
                                </Tab.Container>
                            </Col>
                        </Row>
                    </div>
                </div >
            </Offcanvas >
        </>
    );
});

export default VendorDetails;