/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MainPagetitle from '../../layouts/MainPagetitle';
import { Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import {
    CountryAvailable, CountryCodes, QueryParamsKey, UserGroupDefault,
    VendorIdDefault, VerificationStatusList,
    CustomerTabKeys, AllTabsCustomerForVendor, ACCEPT_BUSINESS_LICENSE_FILE, RegionsGroupDefault, LocationFormat,
    DOCS_FILE_EXTENSION,
    SpecialtyDefault
} from '../../constant/AppConstant';
import locationData from '../../../assets/location-vn.json';
import {
    filterCities, filterDistricts, filterWards, findCityByCode, findCountryByCode,
    findDistrictByCode, findWardByCode, formatDistrictWard
} from '../../../helper/location-helper';
import { CustomerInfoModel, FormDataRequest } from '../../models/customer-info-model';
import { BillingInfoModel, LocCodesModel, ProfileInfoModel, ShippingInfoModel } from '../../models/profile-model';
import { makeEmailFormatHint, makeMobileNumberFormatHint, reFormatPhoneNumber, showToastError, showToastSuccess, showToastWarning, validateLicenseFileSupported } from '../../../helper/helper';
import VendorsService from '../../../services/VendorsService';
import Preloader from '../../layouts/Preloader';
import AssetsService from '../../../services/AssetsService';
import { isRoleAdmin, userDetails } from '../../../services/AuthService';
import { TextApp, TextKey } from '../../../assets/text-app';
import { UnifiedCustomerModel } from '../../models/unified-customer.model';

const EditVendorCustomer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const countries = CountryAvailable;
    const initialized = useRef(false);
    const isAdmin = isRoleAdmin();
    const userLogged = userDetails();
    const [userGroup, setUserGroup] = useState(UserGroupDefault.ListAvailable());
    const [subForensicGroup, setSubForensicGroup] = useState([]);
    const [regionsGroup, setRegionsGroup] = useState(RegionsGroupDefault.ListAvailable());
    const [specialties, setSpecialties] = useState(SpecialtyDefault.ListAvailable());
    const [loading, setLoading] = useState(false);
    const [enableEnterManualProfileAddress, setEnableEnterManualProfileAddress] = useState(false);
    const [enableEnterManualShippingAddress, setEnableEnterManualShippingAddress] = useState(false);
    const [cities, setCities] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [wards, setWards] = useState([]);
    // Billing location
    const [billingCities, setBillingCities] = useState([]);
    const [billingDistricts, setBillingDistricts] = useState([]);
    const [billingWards, setBillingWards] = useState([]);
    // Shipping location
    const [shippingCities, setShippingCities] = useState([]);
    const [shippingDistricts, setShippingDistricts] = useState([]);
    const [shippingWards, setShippingWards] = useState([]);
    const [tabsCustomerInfo, setTabsCustomerInfo] = useState(AllTabsCustomerForVendor);
    // Vendors
    const [vendors, setVendors] = useState([]);
    // Form
    let errorObj = new CustomerInfoModel();
    const [errors, setErrors] = useState(errorObj);
    const [customerDetails, setCustomerDetails] = useState(null);
    const [formCustomerData, setFormCustomerData] = useState(new CustomerInfoModel(userLogged && userLogged.vendorId ? userLogged.vendorId : VendorIdDefault));
    const [formProfile, setFormProfile] = useState(new ProfileInfoModel());
    const [formBilling, setFormBilling] = useState(new BillingInfoModel());
    const [formShipping, setFormShipping] = useState(new ShippingInfoModel());
    const [refIds, setRefIds] = useState([]);
    const [businessLicenseFile, setBusinessLicenseFile] = useState();
    const [pharmaBusinessLicenseFile, setPharmaBusinessLicenseFile] = useState();
    const [gdpGppGspFile, setGdpGppGspFile] = useState();
    const [treatmentLicenseFile, setTreatmentLicenseFile] = useState();
    const [medicalLicenseFile, setMedicalLicenseFile] = useState();
    const [verification, setVerification] = useState('');
    const [status, setStatus] = useState('');
    const [displayFileURL, setDisplayFileURL] = useState('');
    const [refreshForm, setRefreshForm] = useState(false);
    // Enable input billing info
    const [isEnableBilling, setIsEnableBilling] = useState(true);
    const [isEnableBusinessLicense, setIsEnableBusinessLicense] = useState(true);
    const [isEnablePharmaBusinessLicense, setIsEnablePharmaBusinessLicense] = useState(true);
    const [isEnableGDPLicense, setIsEnableGDPLicense] = useState(true);
    const [isEnableTreatmentLicense, setIsEnableTreatmentLicense] = useState(true);
    const [isEnableMedicalLicense, setIsEnableMedicalLicense] = useState(false);    // Only for Doctor
    const [isRequiredGDPLicense, setIsRequiredGDPLicense] = useState(true);
    const [isRequiredTreatmentLicense, setIsRequiredTreatmentLicense] = useState(false);

    const closeToggle = () => {
        setDisplayFileURL('');
    }

    /**
     * Load all cities in Viet Nam
     */
    const loadCities = async () => {
        try {
            const allCities = filterCities(locationData)
            setCities(allCities);
            setBillingCities(allCities);
            setShippingCities(allCities);
        } catch (error) {
            console.warn(error);
        }
    };

    const handleInputFormInfo = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;

        setFormCustomerData((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue
        }));
    }

    const handleInputFormProfile = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;

        setFormProfile((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue
        }));
    }

    const handleInputFormProfileNoSpace = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        validateNoSpace(fieldValue);

        setFormProfile((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue.replace(/\s+/g, "")
        }));
    }

    const handleInputFormBillingNoSpace = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        validateNoSpace(fieldValue);

        setFormBilling((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue.replace(/\s+/g, "")
        }));
    }

    const handleInputFormInfoNoSpace = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        validateNoSpace(fieldValue);

        setFormCustomerData((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue.replace(/\s+/g, "")
        }));
    }

    const handleInputFormShippingNoSpace = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        validateNoSpace(fieldValue);

        setFormShipping((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue.replace(/\s+/g, "")
        }));
    }

    const validateNoSpace = (fieldValue) => {
        const tmpStr = fieldValue.split(' ');

        if (tmpStr && tmpStr.length > 1) {
            showToastWarning(TextApp.getText(TextKey.not_contain_spaces));
        }
    }

    const handleChooseBusinessLicenseFile = async (e) => {
        const isSupported = await validateLicenseFileSupported(e.target.files[0]);

        if (isSupported) {
            setBusinessLicenseFile(e.target.files[0]);
        } else {
            showToastError(`Please provide the valid file (${ACCEPT_BUSINESS_LICENSE_FILE})`);
            setBusinessLicenseFile(null);
        }
    }

    const handleChoosePharmaBusinessLicenseFile = async (e) => {
        const isSupported = await validateLicenseFileSupported(e.target.files[0]);

        if (isSupported) {
            setPharmaBusinessLicenseFile(e.target.files[0]);
        } else {
            showToastError(`Please provide the valid file (${ACCEPT_BUSINESS_LICENSE_FILE})`);
            setPharmaBusinessLicenseFile(null);

        }
    }

    const handleChooseGDPFile = async (e) => {
        const isSupported = await validateLicenseFileSupported(e.target.files[0]);

        if (isSupported) {
            setGdpGppGspFile(e.target.files[0]);
        } else {
            showToastError(`Please provide the valid file (${ACCEPT_BUSINESS_LICENSE_FILE})`);
            setGdpGppGspFile(e.target.files[0]);
        }
    }

    const handleChooseTreatmentLicenseFile = async (e) => {
        const isSupported = await validateLicenseFileSupported(e.target.files[0]);

        if (isSupported) {
            setTreatmentLicenseFile(e.target.files[0]);
        } else {
            showToastError(`Please provide the valid file (${ACCEPT_BUSINESS_LICENSE_FILE})`);
            setTreatmentLicenseFile(null);
        }
    }

    const handleChooseMedicalLicenseFile = async (e) => {
        const isSupported = await validateLicenseFileSupported(e.target.files[0]);

        if (isSupported) {
            setMedicalLicenseFile(e.target.files[0]);
        } else {
            showToastError(`Please provide the valid file (${ACCEPT_BUSINESS_LICENSE_FILE})`);
            setMedicalLicenseFile(null);
        }
    }

    const handleInputFormBilling = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;

        setFormBilling((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue
        }));
    }

    const handleInputFormShipping = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;

        setFormShipping((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue
        }));
    }

    const chooseProfileCountry = (value) => {
        // Profile
        formProfile.locCodes = new LocCodesModel();
        formProfile.locCodes.countryCode = value;
        formProfile.city = '';
        formProfile.state = '';
        formProfile.postalCode = '';
        // Billing
        formBilling.locCodes = new LocCodesModel();
        formBilling.locCodes.countryCode = value;
        formBilling.city = '';
        formBilling.state = '';
        // Shipping
        formShipping.locCodes = new LocCodesModel();
        formShipping.locCodes.countryCode = value;
        formShipping.city = '';
        formShipping.state = '';
        formShipping.postalCode = '';
        setEnableEnterManualProfileAddress(value !== CountryCodes.VIETNAM_COUNTRY_CODE);
        chooseProfileCity('');
        chooseBillingCity('');
        chooseShippingCity('');

        if (value) {
            const countrySelected = findCountryByCode(countries, value);

            if (countrySelected) {
                formProfile.country = countrySelected.name;
                formShipping.country = countrySelected.name;
            }
        }
    };

    const chooseShippingCountry = (value) => {
        formShipping.locCodes.countryCode = value;
        formShipping.city = '';
        formShipping.state = '';
        formShipping.postalCode = '';
        setEnableEnterManualShippingAddress(value !== CountryCodes.VIETNAM_COUNTRY_CODE);
        chooseShippingCity('');

        if (value) {
            const countrySelected = findCountryByCode(countries, value);

            if (countrySelected) {
                formShipping.country = countrySelected.name;
            }
        }
    };

    const chooseProfileCity = (value) => {
        setDistricts([]);
        setWards([]);
        formProfile.locCodes.cityCode = value;
        formProfile.locCodes.districtCode = '';
        formProfile.locCodes.wardCode = '';
        formProfile.city = ''
        formProfile.district = ''
        formProfile.ward = ''

        if (value) {
            const citySelected = findCityByCode(cities, value);

            if (citySelected) {
                formProfile.city = citySelected.NameCity;
            }

            const result = filterDistricts(locationData, value);
            setDistricts(result);
        }
    };

    const chooseProfileDistrict = (value) => {
        setWards([]);
        formProfile.locCodes.districtCode = value;
        formProfile.locCodes.wardCode = '';
        formProfile.district = ''
        formProfile.ward = ''

        if (value) {
            const districtSelected = findDistrictByCode(districts, value);;

            if (districtSelected) {
                formProfile.district = districtSelected.NameDistrict;
            }

            const result = filterWards(locationData, value);
            setWards(result);
        }
    };

    const chooseProfileWard = (value) => {
        formProfile.locCodes.wardCode = value;
        formProfile.ward = ''

        if (value) {
            const wardSelected = findWardByCode(wards, value);;

            if (wardSelected) {
                formProfile.ward = wardSelected.NameWard;
                setWards([...wards]);
            }
        }
        setRefreshForm(!refreshForm);
    };

    const chooseForensicGroup = (value) => {
        formCustomerData.userGroup = value;
        formCustomerData.subForensicGroup = '';
        setUserGroup([...userGroup]);
        // Require special license for Doctor
        setLicenseRequireForDoctor(value === UserGroupDefault.DOCTOR);
        // Find sub-forensic group
        for (let i = 0; i < userGroup.length; i++) {
            if (userGroup[i].keyword === value && userGroup[i].subGroups) {
                setSubForensicGroup([...userGroup[i].subGroups]);
                break;
            }
        }
    };

    const chooseSubForensicGroup = (value) => {
        formCustomerData.subForensicGroup = value;
        setSubForensicGroup([...subForensicGroup]);
    };

    const chooseSpecialty = (value) => {
        formCustomerData.specialty = value;
        formCustomerData.otherSpecialty = '';

        setSpecialties([...specialties]);
    };

    const chooseRegionGroup = (value) => {
        formCustomerData.regionGroup = value;
        setRegionsGroup([...regionsGroup]);
    };

    // Billing info
    const chooseBillingCity = (value) => {
        setBillingDistricts([]);
        setBillingWards([]);

        if (formBilling.locCodes == null) {
            formBilling.locCodes = new LocCodesModel();
            formBilling.locCodes.countryCode = formProfile.locCodes ? formProfile.locCodes.countryCode : '';
        }

        formBilling.locCodes.cityCode = value;
        formBilling.locCodes.districtCode = '';
        formBilling.locCodes.wardCode = '';
        formBilling.city = '';
        formBilling.district = '';
        formBilling.ward = '';

        if (value) {
            const result = filterDistricts(locationData, value);
            const citySelected = findCityByCode(billingCities, value);

            if (citySelected) {
                formBilling.city = citySelected.NameCity;
            }

            setBillingDistricts(result);
        }
    };

    const chooseBillingDistrict = (value) => {
        setBillingWards([]);
        formBilling.locCodes.districtCode = value;
        formBilling.locCodes.wardCode = '';
        formBilling.district = '';
        formBilling.ward = '';

        if (value) {
            const result = filterWards(locationData, value);
            const districtSelected = findDistrictByCode(billingDistricts, value);;

            if (districtSelected) {
                formBilling.district = districtSelected.NameDistrict;
            }

            setBillingWards(result);
        }
    };

    const chooseBillingWard = (value) => {
        formBilling.locCodes.wardCode = value;
        formBilling.ward = '';
        const wardSelected = findWardByCode(billingWards, value);

        if (wardSelected) {
            formBilling.ward = wardSelected.NameWard;
            setBillingWards([...billingWards]);
        }
        setRefreshForm(!refreshForm);
    };

    // Shipping info
    const chooseShippingCity = (value) => {
        setShippingDistricts([]);
        setShippingWards([]);
        formShipping.locCodes.cityCode = value;
        formShipping.locCodes.districtCode = '';
        formShipping.locCodes.wardCode = '';
        formShipping.city = '';
        formShipping.district = '';
        formShipping.ward = '';

        if (value) {
            const result = filterDistricts(locationData, value);
            const citySelected = findCityByCode(shippingCities, value);

            if (citySelected) {
                formShipping.city = citySelected.NameCity;
            }

            setShippingDistricts(result);
        }
    };

    const chooseShippingDistrict = (value) => {
        setShippingWards([]);
        formShipping.locCodes.districtCode = value;
        formShipping.locCodes.wardCode = '';
        formShipping.district = '';
        formShipping.ward = '';

        if (value) {
            const result = filterWards(locationData, value);
            const districtSelected = findDistrictByCode(shippingDistricts, value);;

            if (districtSelected) {
                formShipping.district = districtSelected.NameDistrict;
            }

            setShippingWards(result);
        }
    };

    const chooseShippingWard = (value) => {
        formShipping.locCodes.wardCode = value;
        formShipping.ward = '';
        const wardSelected = findWardByCode(shippingWards, value);;

        if (wardSelected) {
            formShipping.ward = wardSelected.NameWard;
            setShippingWards([...shippingWards]);
        }
        setRefreshForm(!refreshForm);
    };

    const chooseBusinessLicensePlaceOfIssue = (value) => {
        formProfile.locCodes.businessLicensePOICode = value;
        const citySelected = findCityByCode(cities, value);

        if (citySelected) {
            formProfile.businessLicensePlaceOfIssue = citySelected.NameCity;
            setCities([...cities]);
        }

        setRefreshForm(!refreshForm);
    };

    const choosePharmaBusinessLicensePlaceOfIssue = (value) => {
        formProfile.locCodes.pharmaBusinessLicensePOICode = value;
        const citySelected = findCityByCode(cities, value);

        if (citySelected) {
            formProfile.pharmaBusinessLicensePlaceOfIssue = citySelected.NameCity;
            setCities([...cities]);
        }

        setRefreshForm(!refreshForm);
    };

    const chooseGdpGppGspPlaceOfIssue = (value) => {
        formProfile.locCodes.gdpGppGspPOICode = value;
        const citySelected = findCityByCode(cities, value);

        if (citySelected) {
            formProfile.gdpGppGspPlaceOfIssue = citySelected.NameCity;
        }

        setRefreshForm(!refreshForm);
    };

    const chooseTreatmentLicensePlaceOfIssue = (value) => {
        formProfile.locCodes.treatmentLicensePOICode = value;
        const citySelected = findCityByCode(cities, value);

        if (citySelected) {
            formProfile.treatmentLicensePlaceOfIssue = citySelected.NameCity;
        }

        setRefreshForm(!refreshForm);
    };

    const chooseMedicalLicensePlaceOfIssue = (value) => {
        formProfile.locCodes.medicalLicensePOICode = value;
        const citySelected = findCityByCode(cities, value);

        if (citySelected) {
            formProfile.medicalLicensePlaceOfIssue = citySelected.NameCity;
        }

        setRefreshForm(!refreshForm);
    };

    const onChangeDate = e => {
        // const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');
        const fieldName = e.target.name;

        setFormProfile((prevState) => ({
            ...prevState,
            [fieldName]: e.target.value
        }));
    };

    const getBusinessLicenseFileURL = () => {
        let fileURL = '';

        if (customerDetails) {
            fileURL = customerDetails.profileInfo && customerDetails.profileInfo.businessLicenseURL ? customerDetails.profileInfo.businessLicenseURL : '';

            if (customerDetails.keyFile && customerDetails.keyFile.businessLicenseKeyFile) {
                fileURL = AssetsService.getFileURL(customerDetails.keyFile.businessLicenseKeyFile);
            }
        }

        return fileURL;
    };

    const getPharmaBusinessLicenseFileURL = () => {
        let fileURL = '';

        if (customerDetails) {
            fileURL = customerDetails.profileInfo && customerDetails.profileInfo.pharmaBusinessLicenseURL ? customerDetails.profileInfo.pharmaBusinessLicenseURL : '';

            if (customerDetails.keyFile && customerDetails.keyFile.pharmaBusinessLicenseKeyFile) {
                fileURL = AssetsService.getFileURL(customerDetails.keyFile.pharmaBusinessLicenseKeyFile);
            }
        }

        return fileURL;
    };

    const getGDPFileURL = () => {
        let fileURL = '';

        if (customerDetails) {
            fileURL = customerDetails.profileInfo && customerDetails.profileInfo.gdpGppGspURL ? customerDetails.profileInfo.gdpGppGspURL : '';

            if (customerDetails.keyFile && customerDetails.keyFile.gdpGppGspKeyFile) {
                fileURL = AssetsService.getFileURL(customerDetails.keyFile.gdpGppGspKeyFile);
            }
        }

        return fileURL;
    };

    const getTreatmentLicenseFileURL = () => {
        let fileURL = '';

        if (customerDetails) {
            fileURL = customerDetails.profileInfo && customerDetails.profileInfo.treatmentLicenseURL ? customerDetails.profileInfo.treatmentLicenseURL : '';

            if (customerDetails.keyFile && customerDetails.keyFile.treatmentLicenseKeyFile) {
                fileURL = AssetsService.getFileURL(customerDetails.keyFile.treatmentLicenseKeyFile);
            }
        }

        return fileURL;
    };

    const getMedicalLicenseFileURL = () => {
        let fileURL = '';

        if (customerDetails) {
            fileURL = customerDetails.profileInfo && customerDetails.profileInfo.medicalLicenseURL ? customerDetails.profileInfo.medicalLicenseURL : '';

            if (customerDetails.keyFile && customerDetails.keyFile.medicalLicenseKeyFile) {
                fileURL = AssetsService.getFileURL(customerDetails.keyFile.medicalLicenseKeyFile);
            }
        }

        return fileURL;
    };

    const getSignedFileURL = async (url) => {
        try {
            const signedResult = url ? await AssetsService.getSignedURL(url, true) : null;

            if (signedResult) {
                if (signedResult.ext && DOCS_FILE_EXTENSION.indexOf(signedResult.ext) >= 0) {
                    var a = document.createElement('a');
                    a.href = AssetsService.getDocumentFileURL(signedResult.keyFile);
                    a.target = '_blank';
                    a.click();
                } else {
                    setDisplayFileURL(signedResult.url);
                }
            } else {
                setDisplayFileURL('');
            }
        } catch (error) {
            // console.warn(error);
        }
    };

    const setLicenseRequireForDoctor = (isDoctor = true) => {
        // Reset specialty
        formCustomerData.specialty = '';
        formCustomerData.otherSpecialty = '';

        if (isDoctor) {
            // setIsEnableBilling(false);
            // License required
            setIsEnableMedicalLicense(true);
            setIsRequiredTreatmentLicense(true);
            // Not required for doctor
            // setIsEnableBusinessLicense(false);
            // setIsEnablePharmaBusinessLicense(false);
            // setIsEnableGDPLicense(false);
        } else {
            // setIsEnableBilling(true);
            // setIsEnableBusinessLicense(true);
            // setIsEnablePharmaBusinessLicense(true);
            // setIsEnableGDPLicense(true);
            setIsEnableMedicalLicense(false); // Only for doctor
            setIsRequiredTreatmentLicense(false);
        }
    }

    const loadData = async () => {
        const params = new URLSearchParams(location.search)
        const customerId = params && params.get(QueryParamsKey.CUSTOMER_ID) ? params.get(QueryParamsKey.CUSTOMER_ID) : '';

        // Load all cities in Viet Nam
        loadCities();
        // Load all vendors available
        try {
            const allVendors = isAdmin ? await VendorsService.findVendors({}, false, true) : [];
            setVendors(allVendors);

            // Fetch vendor's customer details
            if (customerId) {
                getVendorCustomerDetails(allVendors, customerId);
            }
        } catch (error) {
            console.warn(error);
        }
    };

    const getPhoneNumberFormatHint = () => {
        const countryCode = formProfile && formProfile.locCodes ? formProfile.locCodes.countryCode : CountryCodes.VIETNAM_COUNTRY_CODE;
        return makeMobileNumberFormatHint(countryCode);
    };

    useEffect(() => {
        if (initialized.current)
            return;

        initialized.current = true;
        // Default country / region
        chooseProfileCountry(CountryCodes.VIETNAM_COUNTRY_CODE);
        chooseShippingCountry(CountryCodes.VIETNAM_COUNTRY_CODE);
        // Load data
        loadData();
    }, [navigate, location]);

    /**
     * Get vendor's customer details
     * @param {*} customerId 
     */
    const getVendorCustomerDetails = async (allVendors, customerId) => {
        try {
            setLoading(true);

            await VendorsService.getVendorCustomerDetails(customerId)
                .then((response) => {
                    setLoading(false);

                    if (response) {
                        setCustomerDetails(response);
                        setVerification(response.verification);
                        setFormCustomerData(response);
                        // Forensic group
                        chooseForensicGroup(response.userGroup);
                        // Special license for Doctor
                        setLicenseRequireForDoctor(response.userGroup === UserGroupDefault.DOCTOR);
                        // Profile info
                        if (response.profileInfo) {
                            setFormProfile(response.profileInfo);
                            // Binding location
                            if (response.profileInfo.locCodes && (response.profileInfo.locCodes.countryCode === '' || response.profileInfo.locCodes.countryCode === CountryCodes.VIETNAM_COUNTRY_CODE)) {
                                chooseProfileCity(response.profileInfo.locCodes.cityCode);
                                chooseProfileDistrict(response.profileInfo.locCodes.districtCode);
                                chooseProfileWard(response.profileInfo.locCodes.wardCode);
                            }
                        }
                        // Shipping info
                        if (response.shippingInfo) {
                            setFormShipping(response.shippingInfo);
                            // Binding location
                            if (response.shippingInfo.locCodes && (response.shippingInfo.locCodes.countryCode === '' || response.shippingInfo.locCodes.countryCode === CountryCodes.VIETNAM_COUNTRY_CODE)) {
                                chooseShippingCity(response.shippingInfo.locCodes.cityCode);
                                chooseShippingDistrict(response.shippingInfo.locCodes.districtCode);
                                chooseShippingWard(response.shippingInfo.locCodes.wardCode);
                            }
                        }
                        // Billing info
                        if (response.billingInfo) {
                            setFormBilling(response.billingInfo);
                            // Binding location
                            if (response.billingInfo.locCodes && (response.profileInfo.locCodes && (response.profileInfo.locCodes.countryCode === '' || response.profileInfo.locCodes.countryCode === CountryCodes.VIETNAM_COUNTRY_CODE))) {
                                chooseBillingCity(response.billingInfo.locCodes.cityCode);
                                chooseBillingDistrict(response.billingInfo.locCodes.districtCode);
                                chooseBillingWard(response.billingInfo.locCodes.wardCode);
                            }
                        }
                    } else {
                        if (response && response.message) {
                            showToastError(response.message);
                        }
                    }
                });
        } catch (error) {
            setLoading(false);

            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    /**
     * Handle submit all information to UCD
     * @param {*} e 
     * @returns 
     */
    const handleSubmitInfo = (e) => {
        e.preventDefault();

        // Validate data
        if (!validateData()) {
            showToastWarning(TextApp.getText(TextKey.requied_fields));
            return;
        }

        let unifiedCustomer = new UnifiedCustomerModel();
        let customerInfo = new CustomerInfoModel(userLogged && userLogged.vendorId ? userLogged.vendorId : VendorIdDefault);
        // Form data
        const data = new FormData();

        // if (businessLicenseFile == null || pharmaBusinessLicenseFile == null || gdpGppGspFile == null) {
        //     showToastError(`Please provide the business license/pharma license certificate`);
        //     return;
        // }

        customerInfo = formCustomerData;
        customerInfo.profileInfo = formProfile;
        customerInfo.billingInfo = formBilling;
        customerInfo.shippingInfo = formShipping;
        // Format phone number: [country_code][phone_number]
        const countryCode = formProfile && formProfile.locCodes ? formProfile.locCodes.countryCode : CountryCodes.VIETNAM_COUNTRY_CODE;
        // const profilePhoneNumber = reFormatPhoneNumber(formCustomerData.phoneNumber, countryCode);
        // customerInfo.phoneNumber = profilePhoneNumber;

        const shippingContactNumber = reFormatPhoneNumber(formShipping.contactNumber, countryCode);
        customerInfo.shippingInfo.contactNumber = shippingContactNumber;
        // Reference ID
        // unifiedCustomer.referenceCodes = [];

        if (customerDetails) {
            // Original file
            customerInfo.keyFile = customerDetails.keyFile ? customerDetails.keyFile : null;
        }

        customerInfo.verification = verification;
        // Unified customer
        unifiedCustomer.verification = verification;
        unifiedCustomer.details = customerInfo;
        unifiedCustomer.mappingData();

        // Data submit
        data.append(FormDataRequest.BUSINESS_LICENSE_FILE_KEY, businessLicenseFile);
        data.append(FormDataRequest.PHARMA_BUSINESS_LICENSE_FILE_KEY, pharmaBusinessLicenseFile);
        data.append(FormDataRequest.GDP_GPP_GSP_FILE_KEY, gdpGppGspFile);
        data.append(FormDataRequest.TREATMENT_LICENSE_FILE_KEY, treatmentLicenseFile);
        data.append(FormDataRequest.DATA_KEY, JSON.stringify(unifiedCustomer));

        // Send data to UCD
        sendDataToUCD(data);
    };

    /**
     * Validate form
     * @returns
     */
    const validateData = () => {
        const error = { ...errorObj };
        let resultValidateCustomer = CustomerInfoModel.validate(formCustomerData, error, isAdmin, enableEnterManualProfileAddress, formCustomerData.userGroup !== UserGroupDefault.DOCTOR);
        // Profile
        const resultValidateProfile = ProfileInfoModel.validate(formProfile, error.profileInfo, formCustomerData.userGroup === UserGroupDefault.DOCTOR);
        // Shipping address
        const resultValidateShipping = ShippingInfoModel.validate(formShipping, error.shippingInfo);
        // Billing address
        const resultValidateBilling = isEnableBilling ? BillingInfoModel.validate(formBilling, error.billingInfo) : true;
        // Specialty
        if (formCustomerData.specialty === SpecialtyDefault.OTHER_SPECIALTY && (formCustomerData.otherSpecialty == null || formCustomerData.otherSpecialty === '')) {
            error.specialty = 'Specialty is required';
            resultValidateCustomer = false;
        }

        setErrors(error);

        return resultValidateCustomer && resultValidateProfile && resultValidateShipping && resultValidateBilling;
    }

    /**
    * Send customer info to server
    * @param {} customerInfo 
    */
    const sendDataToUCD = async (customerInfo) => {
        try {
            setLoading(true);

            await VendorsService.updateCustomer(customerDetails.id, customerInfo)
                .then((response) => {
                    setLoading(false);

                    if (response && response.success) {
                        showToastSuccess(TextApp.getText(TextKey.update_successful));
                        resetForm();
                        // navigate(-1);
                        navigate('/vendor-customers', { replace: true });
                    } else {
                        if (response && response.message) {
                            showToastError(response.message);
                        }
                    }
                });
        } catch (error) {
            setLoading(false);

            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    const resetForm = () => {
        let customerInfo = new CustomerInfoModel(userLogged && userLogged.vendorId ? userLogged.vendorId : VendorIdDefault);

        setFormCustomerData(customerInfo);
        setFormProfile(customerInfo.profileInfo);
        setFormBilling(customerInfo.billingInfo);
        setFormShipping(customerInfo.shippingInfo);
    };

    return (
        <>
            <MainPagetitle mainTitle="Customer Management" pageTitle="Profile" parentTitle="Home" />
            <div className="container-fluid">
                {/* <ToastContainer /> */}
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-3">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <h4 className="heading mb-0">Edit Customer</h4>
                                {/* <div className="d-flex align-items-center">
                                    <Link to={"#"} className="btn btn-primary btn-sm ms-2" data-bs-toggle="offcanvas" onClick={() => { }}>
                                        + Import Customer
                                    </Link>
                                </div> */}
                            </div>
                            <div className="dataTables_wrapper no-footer">
                                <Row>
                                    <Col xl={12}>
                                        <Tab.Container defaultActiveKey="Preview">
                                            <Card name="default-tab" className="dz-card">
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="Preview">
                                                        <Card.Body>
                                                            {/* <!-- Nav tabs --> */}
                                                            <div className="default-tab">
                                                                <Tab.Container defaultActiveKey={tabsCustomerInfo.length > 0 ? tabsCustomerInfo[0].key : ''}>
                                                                    <Nav as="ul" className="nav-tabs">
                                                                        {tabsCustomerInfo.map((data, i) => (
                                                                            <Nav.Item as="li" key={i}>
                                                                                <Nav.Link eventKey={data.key}>
                                                                                    <i className={`la la-${data.icon} me-2`} />
                                                                                    {data.name}
                                                                                </Nav.Link>
                                                                            </Nav.Item>
                                                                        ))}
                                                                    </Nav>
                                                                    <Tab.Content className="pt-4">
                                                                        {tabsCustomerInfo.map((data, i) => (
                                                                            <Tab.Pane eventKey={data.key} key={i}>
                                                                                {/* Basic Information */}
                                                                                {data.key === CustomerTabKeys.BASIC_INFORMATION ? (
                                                                                    <form id="#basicInfoForm">
                                                                                        <div className="form-validation">
                                                                                            <div className="row">
                                                                                                <div className="col-xl-6">
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-customer-code">Customer Code<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <input type="text" className="form-control" id="val-customer-code" name="customerCode" value={formCustomerData.customerCode} onChange={handleInputFormInfoNoSpace} placeholder="Enter customer code" />
                                                                                                            {errors.customerCode && <div className="text-danger fs-12">{errors.customerCode}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-user-group">Forensic Group<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <select className="form-control" id="val-user-group" name="val-user-group" value={formCustomerData.userGroup} onChange={(e) => chooseForensicGroup(e.target.value)} required>
                                                                                                                <option value="">Please select</option>
                                                                                                                {userGroup.map((group, i) => { return (<option value={group.keyword} key={i}>{group.name}</option>); })}
                                                                                                            </select>
                                                                                                            {errors.userGroup && <div className="text-danger fs-12">{errors.userGroup}</div>}
                                                                                                            {/* Sub forensic group */}
                                                                                                            {subForensicGroup && subForensicGroup.length > 0 ? <>
                                                                                                                <select className="form-control" id="val-sub-user-group" name="val-sub-user-group" value={formCustomerData.subForensicGroup} onChange={(e) => chooseSubForensicGroup(e.target.value)} required>
                                                                                                                    <option value="">Please select</option>
                                                                                                                    {subForensicGroup.map((group, i) => { return (<option value={group.keyword} key={i}>{group.name}</option>); })}
                                                                                                                </select>
                                                                                                                {errors.subForensicGroup && <div className="text-danger fs-12">{errors.subForensicGroup}</div>}
                                                                                                            </> : <></>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {formCustomerData.userGroup === UserGroupDefault.DOCTOR ? <><div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-specialty">Specialty<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <select className="form-control" id="val-specialty" name="specialty" value={formCustomerData.specialty} onChange={(e) => chooseSpecialty(e.target.value)} required>
                                                                                                                <option value="">Please select</option>
                                                                                                                {specialties.map((group, i) => { return (<option value={group.keyword} key={i}>{group.name}</option>); })}
                                                                                                            </select>
                                                                                                            {formCustomerData.specialty === SpecialtyDefault.OTHER_SPECIALTY ? <>
                                                                                                                <input type="text" className="form-control" id="val-other-specialty" name="otherSpecialty" value={formCustomerData.otherSpecialty} onChange={handleInputFormInfo} placeholder="Enter specialty" required /></> : <></>}
                                                                                                            {errors.specialty && <div className="text-danger fs-12">{errors.specialty}</div>}
                                                                                                        </div>
                                                                                                    </div></> : <></>}
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-company-name">Company Name<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <input type="text" className="form-control" id="company-name" name="companyName" value={formProfile.companyName} onChange={handleInputFormProfile} placeholder="" required />
                                                                                                            {errors.profileInfo.companyName && <div className="text-danger fs-12">{errors.profileInfo.companyName}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-name">Representative Name<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <input type="text" className="form-control" id="representative-name" name="representativeName" value={formCustomerData.representativeName} onChange={handleInputFormInfo} required />
                                                                                                            {errors.representativeName && <div className="text-danger fs-12">{errors.representativeName}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-phone">Contact<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <input type="tel" className="form-control bg-disabled" id="phone-number" name="phoneNumber" value={formCustomerData.phoneNumber} onChange={handleInputFormInfoNoSpace} placeholder={getPhoneNumberFormatHint()} disabled />
                                                                                                            {errors.phoneNumber && <div className="text-danger fs-12">{errors.phoneNumber}</div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-email">Email</label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <input type="email" className="form-control" id="email" name="email" value={formCustomerData.email ? formCustomerData.email : ''} onChange={handleInputFormInfo} placeholder={makeEmailFormatHint()} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-profile-country">Country<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <select className="form-control bg-disabled" id="val-profile-country" name="val-profile-country" value={formProfile.locCodes.countryCode} onChange={(e) => chooseProfileCountry(e.target.value)} disabled required>
                                                                                                                {/* <option value="">Please select</option> */}
                                                                                                                {countries.length > 0 && (
                                                                                                                    countries.map((country, i) => {
                                                                                                                        return (<option value={country.code} key={i}>{country.name}</option>);
                                                                                                                    })
                                                                                                                )}
                                                                                                            </select>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {!enableEnterManualProfileAddress && <><div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-region-group">Region Group<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <select className="form-control" id="val-region-group" name="val-region-group" value={formCustomerData.regionGroup} onChange={(e) => chooseRegionGroup(e.target.value)} required>
                                                                                                                <option value="">Please select</option>
                                                                                                                {regionsGroup.map((group, i) => { return (<option value={group.keyword} key={i}>{group.name}</option>); })}
                                                                                                            </select>
                                                                                                            {errors.regionGroup && <div className="text-danger fs-12">{errors.regionGroup}</div>}
                                                                                                        </div>
                                                                                                    </div></>}
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="profile-address">Address<span className="text-danger">*</span></label>
                                                                                                        <div className='col-lg-7'>
                                                                                                            <div className="mb-2">
                                                                                                                <input type="text" className="form-control" id="profile-address" name="address" value={formProfile.address} onChange={handleInputFormProfile} placeholder="" required />
                                                                                                                {errors.profileInfo.address && <div className="text-danger fs-12">{errors.profileInfo.address}</div>}
                                                                                                            </div>
                                                                                                            {enableEnterManualProfileAddress ? (
                                                                                                                <div>
                                                                                                                    {/* <label className="col-form-label" htmlFor="val-email">Ward</label>
                                                                                                                <input type="text" className="form-control mb-2" id='profile-ward' name="ward" value={formProfile.ward} onChange={handleInputFormProfile} placeholder="" />
                                                                                                                <label className="col-form-label" htmlFor="val-profile-district">District</label>
                                                                                                                <input type="text" className="form-control mb-2" id='profile-district' name="district" value={formProfile.district} onChange={handleInputFormProfile} placeholder="" /> */}
                                                                                                                    <label className="col-form-label" htmlFor="val-profile-city">City</label>
                                                                                                                    <input type="text" className="form-control mb-2" id='profile-city' name="city" value={formProfile.city ? formProfile.city : ''} onChange={handleInputFormProfile} placeholder="" />
                                                                                                                    {errors.profileInfo.city && <div className="text-danger fs-12">{errors.profileInfo.city}</div>}
                                                                                                                    <label className="col-form-label" htmlFor="val-shipping-state">State/Province</label>
                                                                                                                    <input type="text" className="form-control mb-2" id='profile-state' name="state" value={formProfile.state ? formProfile.state : ''} onChange={handleInputFormProfile} placeholder="" />
                                                                                                                    <label className="col-form-label" htmlFor="val-shipping-postal-code">Zip/Postal Code</label>
                                                                                                                    <input type="text" className="form-control" id='profile-postal-code' name="postalCode" value={formProfile.postalCode ? formProfile.postalCode : ''} onChange={handleInputFormProfile} placeholder="" />
                                                                                                                </div>
                                                                                                            ) : (
                                                                                                                <div>
                                                                                                                    <label className="col-form-label" htmlFor="val-profile-city">City<span className="text-danger">*</span></label>
                                                                                                                    <div className='mb-2'>
                                                                                                                        <select className="form-control" value={formProfile.locCodes.cityCode} onChange={(e) => chooseProfileCity(e.target.value)} required>
                                                                                                                            <option value="">Please select</option>
                                                                                                                            {cities.length > 0 && (
                                                                                                                                cities.map((city, i) => {
                                                                                                                                    return (<option value={city.CodeCity} key={i}>{city.NameCity}</option>);
                                                                                                                                })
                                                                                                                            )}
                                                                                                                        </select>
                                                                                                                        {errors.profileInfo.city && <div className="text-danger fs-12">{errors.profileInfo.city}</div>}
                                                                                                                    </div>
                                                                                                                    <label className="col-form-label" htmlFor="val-profile-district">District</label>
                                                                                                                    <select className="form-control mb-2" value={formProfile.locCodes.districtCode} onChange={(e) => chooseProfileDistrict(e.target.value)}>
                                                                                                                        <option value="">Please select</option>
                                                                                                                        {districts.length > 0 && (
                                                                                                                            (districts.map((dis, i) => {
                                                                                                                                return (<option value={dis.CodeDistrict} key={i}>{formatDistrictWard(dis.NameDistrict, LocationFormat.DISTRICT_NUMBER_PREFIX_VN)}</option>);
                                                                                                                            }))
                                                                                                                        )}
                                                                                                                    </select>
                                                                                                                    <label className="col-form-label" htmlFor="val-email">Ward</label>
                                                                                                                    <select className="form-control" value={formProfile.locCodes.wardCode} onChange={(e) => chooseProfileWard(e.target.value)}>
                                                                                                                        <option value="">Please select</option>
                                                                                                                        {wards.length > 0 && (
                                                                                                                            (wards.map((ward, i) => {
                                                                                                                                return (<option value={ward.CodeWard} key={i}>{formatDistrictWard(ward.NameWard, LocationFormat.WARD_NUMBER_PREFIX_VN)}</option>);
                                                                                                                            }))
                                                                                                                        )}
                                                                                                                    </select>
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-xl-6">
                                                                                                    {/* Business license */}
                                                                                                    {isEnableBusinessLicense ? <>
                                                                                                        <div className="form-group mb-3 row">
                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-business-license-no">Business License No.<span className="text-danger">*</span></label>
                                                                                                            <div className="col-lg-7">
                                                                                                                <div className='mb-2'>
                                                                                                                    <input type="text" className="form-control" id="val-business-license-no" name="businessLicenseNo" value={formProfile.businessLicenseNo ? formProfile.businessLicenseNo : ''} onChange={handleInputFormProfileNoSpace} placeholder="" required />
                                                                                                                    {errors.profileInfo.businessLicenseNo && <div className="text-danger fs-12">{errors.profileInfo.businessLicenseNo}</div>}
                                                                                                                </div>
                                                                                                                <label className="col-form-label" htmlFor="val-email">Date Of Issue</label>
                                                                                                                <input type="date" className="form-control mb-2" id="val-business-license-doi" name="businessLicenseDateOfIssue" value={formProfile.businessLicenseDateOfIssue ? formProfile.businessLicenseDateOfIssue : ''} onChange={onChangeDate} placeholder="" />
                                                                                                                <label className="col-form-label" htmlFor="val-email">Place Of Issue</label>
                                                                                                                {enableEnterManualProfileAddress ? (
                                                                                                                    <input type="text" className="form-control mb-3" id="val-business-license-poi" name="businessLicensePlaceOfIssue" value={formProfile.businessLicensePlaceOfIssue ? formProfile.businessLicensePlaceOfIssue : ''} onChange={handleInputFormProfile} placeholder="" />
                                                                                                                ) : (
                                                                                                                    <select className="form-control mb-3" value={formProfile.locCodes.businessLicensePOICode ? formProfile.locCodes.businessLicensePOICode : ''} onChange={(e) => chooseBusinessLicensePlaceOfIssue(e.target.value)}>
                                                                                                                        <option value="">Please select</option>
                                                                                                                        {cities.length > 0 ? (
                                                                                                                            cities.map((city, i) => {
                                                                                                                                return (<option value={city.CodeCity} key={i}>{city.NameCity}</option>);
                                                                                                                            })
                                                                                                                        ) : ('')}
                                                                                                                    </select>
                                                                                                                )}
                                                                                                                <input className="form-control" type="file" id="val-business-license-image" value={formProfile.businessLicenseFile} onChange={handleChooseBusinessLicenseFile} accept={ACCEPT_BUSINESS_LICENSE_FILE} required />
                                                                                                                {getBusinessLicenseFileURL() && (<div className="mt-2">
                                                                                                                    <span className='text-info fs-13' onClick={() => getSignedFileURL(getBusinessLicenseFileURL())}><i className="fa fa-eye me-1"></i>File</span>
                                                                                                                    {/* <a href={getBusinessLicenseFileURL()} target='blank' className='text-info'><i className="fa fa-download"></i>Download</a> */}
                                                                                                                </div>)}
                                                                                                            </div>
                                                                                                        </div></> : <></>}
                                                                                                    {/* Pharma license */}
                                                                                                    {isEnablePharmaBusinessLicense ? <>
                                                                                                        <div className="form-group mb-3 row">
                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-pharma-business-license-no">Pharma Business License No.<span className="text-danger">*</span></label>
                                                                                                            <div className="col-lg-7">
                                                                                                                <div className="mb-2">
                                                                                                                    <input type="text" className="form-control" id="val-pharma-business-license-no" name="pharmaBusinessLicenseNo" value={formProfile.pharmaBusinessLicenseNo ? formProfile.pharmaBusinessLicenseNo : ''} onChange={handleInputFormProfileNoSpace} placeholder="" required />
                                                                                                                    {errors.profileInfo.pharmaBusinessLicenseNo && <div className="text-danger fs-12">{errors.profileInfo.pharmaBusinessLicenseNo}</div>}
                                                                                                                </div>
                                                                                                                <label className="col-form-label" htmlFor="val-email">Date Of Issue</label>
                                                                                                                <input type="date" className="form-control mb-2" id="val-pharma-business-license-doi" name="pharmaBusinessLicenseDateOfIssue" value={formProfile.pharmaBusinessLicenseDateOfIssue ? formProfile.pharmaBusinessLicenseDateOfIssue : ''} onChange={onChangeDate} placeholder="" />
                                                                                                                <label className="col-form-label" htmlFor="val-email">Place Of Issue</label>
                                                                                                                {enableEnterManualProfileAddress ? (
                                                                                                                    <input type="text" className="form-control mb-3" id="val-pharma-business-license-poi" name="pharmaBusinessLicensePlaceOfIssue" value={formProfile.pharmaBusinessLicensePlaceOfIssue ? formProfile.pharmaBusinessLicensePlaceOfIssue : ''} onChange={handleInputFormProfile} placeholder="" />
                                                                                                                ) : (
                                                                                                                    <select className="form-control mb-3" value={formProfile.locCodes.pharmaBusinessLicensePOICode ? formProfile.locCodes.pharmaBusinessLicensePOICode : ''} onChange={(e) => choosePharmaBusinessLicensePlaceOfIssue(e.target.value)}>
                                                                                                                        <option value="">Please select</option>
                                                                                                                        {cities.length > 0 ? (
                                                                                                                            cities.map((city, i) => {
                                                                                                                                return (<option value={city.CodeCity} key={i}>{city.NameCity}</option>);
                                                                                                                            })
                                                                                                                        ) : ('')}
                                                                                                                    </select>
                                                                                                                )}
                                                                                                                <input className="form-control" type="file" id="val-pharma-business-license-image" name="pharmaBusinessLicenseFile" onChange={handleChoosePharmaBusinessLicenseFile} accept={ACCEPT_BUSINESS_LICENSE_FILE} required />
                                                                                                                {getPharmaBusinessLicenseFileURL() && (<div className="mt-2">
                                                                                                                    <span className='text-info fs-13' onClick={() => getSignedFileURL(getPharmaBusinessLicenseFileURL())}><i className="fa fa-eye me-1"></i>File</span>
                                                                                                                    {/* <a href={getPharmaBusinessLicenseFileURL()} target='blank' className='text-info'><i className="fa fa-download"></i>Download</a> */}
                                                                                                                </div>)}
                                                                                                            </div>
                                                                                                        </div></> : <></>}
                                                                                                    {/* GDP license */}
                                                                                                    {isEnableGDPLicense ? <>
                                                                                                        <div className="form-group mb-3 row">
                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-gdp-gpp-gsp-no">GDP/GPP/GSP No<span className="text-danger">*</span></label>
                                                                                                            <div className="col-lg-7">
                                                                                                                <div className="mb-2">
                                                                                                                    <input type="text" className="form-control" id="val-gdp-gpp-gsp-no" name="gdpGppGspNo" value={formProfile.gdpGppGspNo} onChange={handleInputFormProfileNoSpace} placeholder="" required />
                                                                                                                    {errors.profileInfo.gdpGppGspNo && <div className="text-danger fs-12">{errors.profileInfo.gdpGppGspNo}</div>}
                                                                                                                </div>
                                                                                                                <label className="col-form-label" htmlFor="val-gdp-gpp-gsp-doi">Date Of Issue</label>
                                                                                                                <input type="date" className="form-control mb-2" id="val-gdp-gpp-gsp-doi" name="gdpGppGspDateOfIssue" value={formProfile.gdpGppGspDateOfIssue ? formProfile.gdpGppGspDateOfIssue : ''} onChange={onChangeDate} placeholder="" />
                                                                                                                <label className="col-form-label" htmlFor="">Place Of Issue</label>
                                                                                                                {enableEnterManualProfileAddress ? (
                                                                                                                    <>
                                                                                                                        <input type="text" className="form-control mb-3" id="val-gdp-gpp-gsp-poi" name="gdpGppGspPlaceOfIssue" value={formProfile.gdpGppGspPlaceOfIssue ? formProfile.gdpGppGspPlaceOfIssue : ''} onChange={handleInputFormProfile} placeholder="" />
                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    <>
                                                                                                                        <select className="form-control mb-3" value={formProfile.locCodes.gdpGppGspPOICode ? formProfile.locCodes.gdpGppGspPOICode : ''} onChange={(e) => chooseGdpGppGspPlaceOfIssue(e.target.value)}>
                                                                                                                            <option value="">Please select</option>
                                                                                                                            {cities.length > 0 ? (
                                                                                                                                cities.map((city, i) => {
                                                                                                                                    return (<option value={city.CodeCity} key={i}>{city.NameCity}</option>);
                                                                                                                                })
                                                                                                                            ) : ('')}
                                                                                                                        </select>
                                                                                                                    </>
                                                                                                                )}
                                                                                                                <input className="form-control" type="file" id="val-gdp-gpp-gsp-image" name="gdpGppGspFile" onChange={handleChooseGDPFile} accept={ACCEPT_BUSINESS_LICENSE_FILE} required />
                                                                                                                {getGDPFileURL() && (<div className="mt-2">
                                                                                                                    <span className='text-info fs-13' onClick={() => getSignedFileURL(getGDPFileURL())}><i className="fa fa-eye me-1"></i>File</span>
                                                                                                                </div>)}
                                                                                                            </div>
                                                                                                        </div></> : <></>}
                                                                                                    {/* Treatment license */}
                                                                                                    {isEnableTreatmentLicense ? <>
                                                                                                        <div className="form-group mb-3 row">
                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-treatment-license-no">Treatment License No{isRequiredTreatmentLicense ? <><span className="text-danger">*</span></> : <></>}</label>
                                                                                                            <div className="col-lg-7">
                                                                                                                <div className="mb-2">
                                                                                                                    <input type="text" className="form-control" id="val-treatment-license-no" name="treatmentLicenseNo" value={formProfile.treatmentLicenseNo} onChange={handleInputFormProfileNoSpace} placeholder="" required={isRequiredTreatmentLicense} />
                                                                                                                    {errors.profileInfo.treatmentLicenseNo && <div className="text-danger fs-12">{errors.profileInfo.treatmentLicenseNo}</div>}
                                                                                                                </div>
                                                                                                                <label className="col-form-label" htmlFor="val-treatment-license-doi">Date Of Issue</label>
                                                                                                                <input type="date" className="form-control mb-2" id="val-treatment-license-doi" name="treatmentLicenseDateOfIssue" value={formProfile.treatmentLicenseDateOfIssue ? formProfile.treatmentLicenseDateOfIssue : ''} onChange={onChangeDate} placeholder="" />
                                                                                                                <label className="col-form-label" htmlFor="">Place Of Issue</label>
                                                                                                                {enableEnterManualProfileAddress ? (
                                                                                                                    <>
                                                                                                                        <input type="text" className="form-control mb-3" id="val-treatment-license-poi" name="treatmentLicensePlaceOfIssue" value={formProfile.treatmentLicensePlaceOfIssue ? formProfile.treatmentLicensePlaceOfIssue : ''} onChange={handleInputFormProfile} placeholder="" />
                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    <>
                                                                                                                        <select className="form-control mb-3" value={formProfile.locCodes.treatmentLicensePOICode ? formProfile.locCodes.treatmentLicensePOICode : ''} onChange={(e) => chooseTreatmentLicensePlaceOfIssue(e.target.value)}>
                                                                                                                            <option value="">Please select</option>
                                                                                                                            {cities.length > 0 ? (
                                                                                                                                cities.map((city, i) => {
                                                                                                                                    return (<option value={city.CodeCity} key={i}>{city.NameCity}</option>);
                                                                                                                                })
                                                                                                                            ) : ('')}
                                                                                                                        </select>
                                                                                                                    </>
                                                                                                                )}
                                                                                                                <input className="form-control" type="file" id="val-treatment-license-image" name="treatmentLicenseFile" onChange={handleChooseTreatmentLicenseFile} accept={ACCEPT_BUSINESS_LICENSE_FILE} />
                                                                                                                {getTreatmentLicenseFileURL() && (<div className="mt-2">
                                                                                                                    <span className='text-info fs-13' onClick={() => getSignedFileURL(getTreatmentLicenseFileURL())}><i className="fa fa-eye me-1"></i>File</span>
                                                                                                                </div>)}
                                                                                                            </div>
                                                                                                        </div></> : <></>}
                                                                                                    {/* Medical license */}
                                                                                                    {isEnableMedicalLicense ? <>
                                                                                                        <div className="form-group mb-3 row">
                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-medical-license-no">Medical License No<span className="text-danger">*</span></label>
                                                                                                            <div className="col-lg-7">
                                                                                                                <div className="mb-2">
                                                                                                                    <input type="text" className="form-control" id="val-medical-license-no" name="medicalLicenseNo" value={formProfile.medicalLicenseNo} onChange={handleInputFormProfileNoSpace} placeholder="" required />
                                                                                                                    {errors.profileInfo.medicalLicenseNo && <div className="text-danger fs-12">{errors.profileInfo.medicalLicenseNo}</div>}
                                                                                                                </div>
                                                                                                                <label className="col-form-label" htmlFor="val-medical-license-doi">Date Of Issue</label>
                                                                                                                <input type="date" className="form-control mb-2" id="val-medical-license-doi" name="medicalLicenseDateOfIssue" value={formProfile.medicalLicenseDateOfIssue ? formProfile.medicalLicenseDateOfIssue : ''} onChange={onChangeDate} placeholder="" />
                                                                                                                <label className="col-form-label" htmlFor="">Place Of Issue</label>
                                                                                                                {enableEnterManualProfileAddress ? (
                                                                                                                    <>
                                                                                                                        <input type="text" className="form-control mb-3" id="val-medical-license-poi" name="medicalLicensePlaceOfIssue" value={formProfile.medicalLicensePlaceOfIssue ? formProfile.medicalLicensePlaceOfIssue : ''} onChange={handleInputFormProfile} placeholder="" />
                                                                                                                    </>
                                                                                                                ) : (
                                                                                                                    <>
                                                                                                                        <select className="form-control mb-3" value={formProfile.locCodes.medicalLicensePOICode ? formProfile.locCodes.medicalLicensePOICode : ''} onChange={(e) => chooseMedicalLicensePlaceOfIssue(e.target.value)}>
                                                                                                                            <option value="">Please select</option>
                                                                                                                            {cities.length > 0 ? (
                                                                                                                                cities.map((city, i) => {
                                                                                                                                    return (<option value={city.CodeCity} key={i}>{city.NameCity}</option>);
                                                                                                                                })
                                                                                                                            ) : ('')}
                                                                                                                        </select>
                                                                                                                    </>
                                                                                                                )}
                                                                                                                <input className="form-control" type="file" id="val-medical-license-image" name="medicalLicenseFile" onChange={handleChooseMedicalLicenseFile} accept={ACCEPT_BUSINESS_LICENSE_FILE} />
                                                                                                                <div className="mt-2">
                                                                                                                    {getMedicalLicenseFileURL() && <>
                                                                                                                        <span className='text-info fs-13' onClick={() => getSignedFileURL(getMedicalLicenseFileURL())}><i className="fa fa-eye me-1"></i>File</span>
                                                                                                                    </>}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div></> : <></>}
                                                                                                    <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label text-danger" htmlFor="val-verification">Verification</label>
                                                                                                        <div className="col-lg-7">
                                                                                                            {/* <input type="text" className="form-control" id="val-verification" name="val-verification" value={verification} disabled/> */}
                                                                                                            <select className="form-control text-danger bg-disabled" id="val-verification" name="val-verification" value={verification} /* onChange={(e) => chooseVerification(e.target.value)} */ disabled>
                                                                                                                <option value="">N/A</option>
                                                                                                                {VerificationStatusList.map((s, i) => {
                                                                                                                    return (<option value={s.key} key={i}>{s.title}</option>);
                                                                                                                })}
                                                                                                            </select>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {/* {verification === VerificationStatus.APPROVED && (<div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label text-danger" htmlFor="val-status">Status</label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <select className="form-control" id="val-status" name="val-status" value={status} onChange={(e) => setStatus(e.target.value)}>
                                                                                                                <option value="">Please select</option>
                                                                                                                {UserStatusList.map((s, i) => {
                                                                                                                    return (<option value={s.key} key={i}>{s.title}</option>);
                                                                                                                })}
                                                                                                            </select>
                                                                                                        </div>
                                                                                                    </div>)} */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </form>
                                                                                ) : ''}
                                                                                {/* Segments for the each tab */}
                                                                                {data.segments && data.segments.length > 0 ? <>
                                                                                    {data.segments.map((seg, segInd) => (
                                                                                        <div key={seg.key}>
                                                                                            {/* Billing Address */}
                                                                                            {seg.key === CustomerTabKeys.BILLING_ADDRESS && isEnableBilling ? <>
                                                                                                <label className="col-form-label text-menu-label fs-6 pb-0" htmlFor="">{seg.name}</label>
                                                                                                <hr />
                                                                                                <form id="#billingForm">
                                                                                                    <div className="form-validation">
                                                                                                        <div className="row">
                                                                                                            <div className="col-xl-6">
                                                                                                                <div className="form-group mb-3 row">
                                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-billing-name">Name<span className="text-danger">*</span></label>
                                                                                                                    <div className="col-lg-7">
                                                                                                                        <input type="text" className="form-control" id="val-billing-name" name="name" value={formBilling.name} onChange={handleInputFormBilling} placeholder="" required />
                                                                                                                        {errors.billingInfo.name && <div className="text-danger fs-12">{errors.billingInfo.name}</div>}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="form-group mb-3 row">
                                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-billing-tax-id">Tax ID<span className="text-danger">*</span></label>
                                                                                                                    <div className="col-lg-7">
                                                                                                                        <input type="text" className="form-control" id="val-billing-tax-id" name="taxCode" value={formBilling.taxCode} onChange={handleInputFormBillingNoSpace} placeholder="" required />
                                                                                                                        {errors.billingInfo.taxCode && <div className="text-danger fs-12">{errors.billingInfo.taxCode}</div>}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col-xl-6">
                                                                                                                <div className="form-group mb-3 row">
                                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-billing-address">Address<span className="text-danger">*</span></label>
                                                                                                                    <div className="col-lg-7">
                                                                                                                        <input type="text" className="form-control" id="val-billing-address" name="address" value={formBilling.address} onChange={handleInputFormBilling} placeholder="" required />
                                                                                                                        {errors.billingInfo.address && <div className="text-danger fs-12">{errors.billingInfo.address}</div>}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                {enableEnterManualProfileAddress ? (
                                                                                                                    <div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-billing-city">City<span className="text-danger">*</span></label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                <input type="text" className="form-control" id="val-billing-city" name="city" value={formBilling && formBilling.city ? formBilling.city : ''} onChange={handleInputFormBilling} placeholder="" />
                                                                                                                                {errors.billingInfo.city && <div className="text-danger fs-12">{errors.billingInfo.city}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-billing-state">State/Province</label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                <input type="text" className="form-control" id="val-billing-state" name="state" value={formBilling && formBilling.state ? formBilling.state : ''} onChange={handleInputFormBilling} placeholder="" />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-billing-postal-code">Zip/Postal Code</label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                <input type="text" className="form-control" id="val-billing-postal-code" name="postalCode" value={formBilling.postalCode ? formBilling.postalCode : ''} onChange={handleInputFormBilling} placeholder="" />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    <div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-billing-city">City<span className="text-danger">*</span></label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                <select className="form-control" value={formBilling.locCodes && formBilling.locCodes.cityCode ? formBilling.locCodes.cityCode : ''} onChange={(e) => chooseBillingCity(e.target.value)} required>
                                                                                                                                    <option value="">Please select</option>
                                                                                                                                    {billingCities.length > 0 && (
                                                                                                                                        billingCities.map((city, i) => {
                                                                                                                                            return (<option value={city.CodeCity} key={i}>{city.NameCity}</option>);
                                                                                                                                        })
                                                                                                                                    )}
                                                                                                                                </select>
                                                                                                                                {errors.billingInfo.city && <div className="text-danger fs-12">{errors.billingInfo.city}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-billing-district">District</label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                <select className="form-control" value={formBilling.locCodes && formBilling.locCodes.districtCode ? formBilling.locCodes.districtCode : ''} onChange={(e) => chooseBillingDistrict(e.target.value)}>
                                                                                                                                    <option value="">Please select</option>
                                                                                                                                    {billingDistricts.length > 0 && (
                                                                                                                                        (billingDistricts.map((dis, i) => {
                                                                                                                                            return (<option value={dis.CodeDistrict} key={i}>{formatDistrictWard(dis.NameDistrict, LocationFormat.DISTRICT_NUMBER_PREFIX_VN)}</option>);
                                                                                                                                        }))
                                                                                                                                    )}
                                                                                                                                </select>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-billing-ward">Ward</label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                <select className="form-control" value={formBilling.locCodes && formBilling.locCodes.wardCode ? formBilling.locCodes.wardCode : ''} onChange={(e) => chooseBillingWard(e.target.value)}>
                                                                                                                                    <option value="">Please select</option>
                                                                                                                                    {billingWards.length > 0 && (
                                                                                                                                        (billingWards.map((ward, i) => {
                                                                                                                                            return (<option value={ward.CodeWard} key={i}>{formatDistrictWard(ward.NameWard, LocationFormat.WARD_NUMBER_PREFIX_VN)}</option>);
                                                                                                                                        }))
                                                                                                                                    )}
                                                                                                                                </select>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </form></> : ''}
                                                                                            {/* Shipping Address*/}
                                                                                            {seg.key === CustomerTabKeys.SHIPPING_ADDRESS ? <>
                                                                                                <label className="col-form-label text-menu-label fs-6 pb-0" htmlFor="">{seg.name}</label>
                                                                                                <hr />
                                                                                                <form id="#shippingForm">
                                                                                                    <div className="form-validation">
                                                                                                        <div className="row">
                                                                                                            <div className="col-xl-6">
                                                                                                                <div className="form-group mb-3 row">
                                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-shipping-contact-name">Contact Name<span className="text-danger">*</span></label>
                                                                                                                    <div className="col-lg-7">
                                                                                                                        <input type="text" className="form-control" id="val-shipping-contact-name" name="contactName" value={formShipping.contactName} onChange={handleInputFormShipping} placeholder="" required />
                                                                                                                        {errors.shippingInfo.contactName && <div className="text-danger fs-12">{errors.shippingInfo.contactName}</div>}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="form-group mb-3 row">
                                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-shipping-contact-number">Contact Number<span className="text-danger">*</span></label>
                                                                                                                    <div className="col-lg-7">
                                                                                                                        <input type="tel" className="form-control" id="val-shipping-contact-number" name="contactNumber" value={formShipping.contactNumber} onChange={handleInputFormShippingNoSpace} placeholder={getPhoneNumberFormatHint()} required />
                                                                                                                        {errors.shippingInfo.contactNumber && <div className="text-danger fs-12">{errors.shippingInfo.contactNumber}</div>}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                {/* <div className="form-group mb-3 row">
                                                                                                        <label className="col-lg-4 col-form-label" htmlFor="val-shipping-country">Country<span className="text-danger">*</span></label>
                                                                                                        <div className="col-lg-7">
                                                                                                            <select className="form-control" id="val-shipping-country" name="val-shipping-country" onChange={(e) => chooseShippingCountry(e.target.value)} required>
                                                                                                                {countries.length > 0 && (
                                                                                                                    countries.map((country, i) => {
                                                                                                                        return (<option value={country.code} key={i}>{country.name}</option>);
                                                                                                                    })
                                                                                                                )}
                                                                                                            </select>
                                                                                                        </div>
                                                                                                    </div> */}
                                                                                                            </div>
                                                                                                            <div className="col-xl-6">
                                                                                                                <div className="form-group mb-3 row">
                                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-shipping-address">Address<span className="text-danger">*</span></label>
                                                                                                                    <div className="col-lg-7">
                                                                                                                        <input type="text" className="form-control" id="val-shipping-address" name="address" value={formShipping.address} onChange={handleInputFormShipping} placeholder="" required />
                                                                                                                        {errors.shippingInfo.address && <div className="text-danger fs-12">{errors.shippingInfo.address}</div>}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                {enableEnterManualProfileAddress ? (
                                                                                                                    <div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-shipping-city">City<span className="text-danger">*</span></label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                <input type="text" className="form-control" id="val-shipping-city" name="city" value={formShipping.city} onChange={handleInputFormShipping} placeholder="" />
                                                                                                                                {errors.shippingInfo.city && <div className="text-danger fs-12">{errors.shippingInfo.city}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-shipping-state">State/Province</label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                <input type="text" className="form-control" id="val-shipping-state" name="state" value={formShipping.state ? formShipping.state : ''} onChange={handleInputFormShipping} placeholder="" />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-shipping-postal-code">Zip/Postal Code</label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                <input type="text" className="form-control" id="val-shipping-postal-code" name="postalCode" value={formShipping.postalCode ? formShipping.postalCode : ''} onChange={handleInputFormShipping} placeholder="" />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    <div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-shipping-city">City<span className="text-danger">*</span></label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                <select className="form-control" value={formShipping.locCodes.cityCode} onChange={(e) => chooseShippingCity(e.target.value)} required>
                                                                                                                                    <option value="">Please select</option>
                                                                                                                                    {shippingCities.length > 0 && (
                                                                                                                                        shippingCities.map((city, i) => {
                                                                                                                                            return (<option value={city.CodeCity} key={i}>{city.NameCity}</option>);
                                                                                                                                        })
                                                                                                                                    )}
                                                                                                                                </select>
                                                                                                                                {errors.shippingInfo.city && <div className="text-danger fs-12">{errors.shippingInfo.city}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-shipping-district">District<span className="text-danger">*</span></label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                <select className="form-control" value={formShipping.locCodes.districtCode} onChange={(e) => chooseShippingDistrict(e.target.value)}>
                                                                                                                                    <option value="">Please select</option>
                                                                                                                                    {shippingDistricts.length > 0 && (
                                                                                                                                        (shippingDistricts.map((dis, i) => {
                                                                                                                                            return (<option value={dis.CodeDistrict} key={i}>{formatDistrictWard(dis.NameDistrict, LocationFormat.DISTRICT_NUMBER_PREFIX_VN)}</option>);
                                                                                                                                        }))
                                                                                                                                    )}
                                                                                                                                </select>
                                                                                                                                {errors.shippingInfo.district && <div className="text-danger fs-12">{errors.shippingInfo.district}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="form-group mb-3 row">
                                                                                                                            <label className="col-lg-4 col-form-label" htmlFor="val-shipping-ward">Ward<span className="text-danger">*</span></label>
                                                                                                                            <div className="col-lg-7">
                                                                                                                                <select className="form-control" value={formShipping.locCodes.wardCode} onChange={(e) => chooseShippingWard(e.target.value)}>
                                                                                                                                    <option value="">Please select</option>
                                                                                                                                    {shippingWards.length > 0 && (
                                                                                                                                        (shippingWards.map((ward, i) => {
                                                                                                                                            return (<option value={ward.CodeWard} key={i}>{formatDistrictWard(ward.NameWard, LocationFormat.WARD_NUMBER_PREFIX_VN)}</option>);
                                                                                                                                        }))
                                                                                                                                    )}
                                                                                                                                </select>
                                                                                                                                {errors.shippingInfo.ward && <div className="text-danger fs-12">{errors.shippingInfo.ward}</div>}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </form>
                                                                                            </> : ''}
                                                                                        </div>
                                                                                    ))}
                                                                                </> : <></>}
                                                                            </Tab.Pane>
                                                                        ))}
                                                                    </Tab.Content>
                                                                </Tab.Container>
                                                            </div>
                                                        </Card.Body>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Card>
                                        </Tab.Container>
                                    </Col>
                                </Row>
                                <div className="form-group row">
                                    <div className="col-xl-12 text-end">
                                        <button className="btn btn-primary" onClick={handleSubmitInfo}>Update Customer</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loading && (<div className="dialog-blur">{<Preloader />}</div>)}
                {displayFileURL && (
                    <div className="dialog-blur">
                        <div className="dialog-content">
                            <div className="text-end">
                                <span className='me-5'>
                                    <a onClick={closeToggle} href={displayFileURL} target='blank' className='text-info'><i className="fa fa-download"></i>Download File</a>
                                </span>
                                <span onClick={closeToggle} className='text-black'><i className="fa fa-times"></i> Close</span>
                            </div>
                            <hr />
                            <img src={displayFileURL} alt="" />
                        </div>
                    </div>
                )}
            </div >
        </>
    )
}

export default EditVendorCustomer;