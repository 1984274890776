import { UserStatus } from "../constant/AppConstant";

export const VendorFormDataRequest = {
    LOGO_KEY: 'logoFile',
    DATA_KEY: 'data',
};

export class VendorInfoModel {
    constructor(vietpomCallbackDefault = '', vietpomSubUsersCallbackDefault = '') {
        this.vendorId = '';
        this.companyName = '';
        this.businessRegNo = '';
        this.email = '';
        this.contact = '';
        this.address = '';
        this.status = UserStatus.ACTIVE;
        this.logoURL = '';
        this.vendorAuthCallbackEndpoint = '';
        this.vendorIdentityCallbackEndpoint = '';
        this.vendorCredential = '';
        this.vietpomCallbackEndpoint = vietpomCallbackDefault;
        this.vietpomSubUsersCallbackEndpoint = vietpomSubUsersCallbackDefault;
        this.vietpomCredential = '';
        this.keyFile = null;
        this.adminAccounts = [];
    }

    static validate(obj, error) {
        let result = true;

        if (obj.vendorId == null || obj.vendorId === '') {
            error.vendorId = 'Vendor ID is required';
            result = false;
        }
        if (obj.companyName == null || obj.companyName === '') {
            error.companyName = 'Company Name is required';
            result = false;
        }
        if (obj.businessRegNo == null || obj.businessRegNo === '') {
            error.businessRegNo = 'Business Reg No is required';
            result = false;
        }
        if (obj.contact == null || obj.contact === '') {
            error.contact = 'Contact Number is required';
            result = false;
        }
        if (obj.email == null || obj.email === '') {
            error.email = 'Email is required';
            result = false;
        }
        if (obj.address == null || obj.address === '') {
            error.address = 'Address is required';
            result = false;
        }
        // if (obj.vietpomCallbackEndpoint === '') {
        //     error.vietpomCallbackEndpoint = 'VietPOM Callback Endpoint is required';
        //     result = false;
        // }
        // if (obj.vietpomCredential == null || obj.vietpomCredential === '') {
        //     error.vietpomCredential = 'Credential Payload is required';
        //     result = false;
        // }

        return result;
    }
};