import { SVGICON } from "../../constant/theme";

// For admin
export const MenuList = [
    //Dashboard
    {
        title: 'Dashboard',
        iconStyle: SVGICON.Home,
        to: '/'
    },
    {
        title: 'Vendors',
        iconStyle: SVGICON.Vendor,
        classsChange: 'mm-collapse',
        content: [
            {
                title: 'List',
                to: '/vendors'
            },
            // {
            //     title: 'Add Vendor',
            //     to: '/add-vendor',
            // },
        ]
    },
    {
        title: 'Customers',
        iconStyle: SVGICON.Profile,
        classsChange: 'mm-collapse',
        content: [
            {
                title: 'List',
                to: '/customers',
            },
            {
                title: 'Add Customer',
                to: '/add-customer',
            },
        ]
    },
    {
        title: 'Admin Management',
        iconStyle: SVGICON.Profile,
        classsChange: 'mm-collapse',
        content: [
            {
                title: 'List',
                to: '/accounts',
            },
            {
                title: 'Add Account',
                to: '/add-account',
            },
        ]
    },
    {
        title: 'Settings',
        iconStyle: SVGICON.SettingMenu,
        to: '/settings'
    }
];

// For Vendor admin
export const MenuListVendorOnly = [
    //Dashboard
    {
        title: 'Dashboard',
        iconStyle: SVGICON.Home,
        to: '/'
    },
    {
        title: 'Customers',
        iconStyle: SVGICON.Profile,
        classsChange: 'mm-collapse',
        content: [
            {
                title: 'List',
                to: '/vendor-customers',
            },
            {
                title: 'Add Customer',
                to: '/add-vendor-customer',
            },
        ]
    }
]