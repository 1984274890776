export class SubUserModel {
    constructor(pomId = '') {
        this.id = '';
        this.pomId = pomId;
        this.customerNo = '';   // ucd_code
        this.accountNo = '';
        this.name = '';
        this.email = '';
        this.phoneNumber = '';
        this.fax = '';
        this.role = '';
    }

    static validate(obj, error) {
        let result = true;

        if (obj.name == null || obj.name === '') {
            error.name = 'Name is required';
            result = false;
        }

        if (obj.phoneNumber == null || obj.phoneNumber === '') {
            error.phoneNumber = 'Phone number is required';
            result = false;
        }

        if (obj.role == null || obj.role === '') {
            error.role = 'Role is required';
            result = false;
        }

        return result;
    }
}