import axios, { HttpStatusCode } from 'axios';
import { getLocalData, parseFilterToString, showToastError, showToastSuccess } from '../helper/helper';

export const ApiEndpoint = process.env.REACT_APP_STAGE === 'prod' ? process.env.REACT_APP_API_ENDPOINT_PRODUCTION
    : (process.env.REACT_APP_STAGE === 'staging' ? process.env.REACT_APP_API_ENDPOINT_STAGING : process.env.REACT_APP_API_ENDPOINT_LOCAL);
export const VietPOMCallbackEndpointDefault = process.env.REACT_APP_STAGE === 'prod' ? process.env.REACT_APP_VIETPOM_CALLBACK_ENDPOINT_PRODUCTION
    : (process.env.REACT_APP_STAGE === 'staging' ? process.env.REACT_APP_VIETPOM_CALLBACK_ENDPOINT_STAGING : process.env.REACT_APP_VIETPOM_CALLBACK_ENDPOINT_DEV);
export const VietPOMSubUsersCallbackEndpointDefault = process.env.REACT_APP_STAGE === 'prod' ? process.env.REACT_APP_VIETPOM_SUB_USERS_CALLBACK_ENDPOINT_PRODUCTION
    : (process.env.REACT_APP_STAGE === 'staging' ? process.env.REACT_APP_VIETPOM_SUB_USERS_CALLBACK_ENDPOINT_STAGING : process.env.REACT_APP_VIETPOM_SUB_USERS_CALLBACK_ENDPOINT_DEV);

export const LOCAL_STORAGE_KEY = {
    TOKEN_DETAILS_KEY: 'token_details',
    USER_DETAILS_KEY: 'user_details'
};

export const PageSize = {
    LIMIT_ITEMS_PER_PAGE_DEFAULT: 20,
    LIMIT_ITEMS_PER_PAGE_50: 50,
    LIMIT_ITEMS_PER_PAGE_100: 100
};

export const HttpMethod = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE'
};

// Not use: Only for testing
axios.create({ baseURL: ApiEndpoint });

axios.interceptors.request.use((config) => {
    const tokenDetails = getLocalData(LOCAL_STORAGE_KEY.TOKEN_DETAILS_KEY);

    if (tokenDetails && tokenDetails.accessToken) {
        config.headers.common["Authorization"] = tokenDetails.accessToken ? "Bearer " + tokenDetails.accessToken : "";
    }

    config.headers.common['language'] = 'en';

    return config;
});

axios.interceptors.response.use(
    (response) => {
        // Do something with response data
        // if (response && response.status === HttpStatusCode.Ok) {
        // const { data } = response || {};
        // if (data && data.message) {
        //     toast.success(`${data.message}`, { theme: 'colored' });
        // }
        // }

        return response;
    },
    (error) => {
        // Do something with response error
        const { response } = error || {};
        // const originalRequest = config;
        // const { data } = response || {};

        // if (data && data.message) {
        //     toast.error(`${data.message}`, { theme: 'colored' });
        // }
        return Promise.reject(response);
        // if (response.status === HttpStatusCode.Unauthorized || response.status === HttpStatusCode.NotFound) {
        // } else {
        // }
    }
);

export default class Api {
    static async post(action, params) {
        let response = await axios.post(action, params);
        return response.data;
    }

    static async put(action, params) {
        let response = await axios.put(action, params);
        return response.data;
    }

    static async get(action, filter = {}, isPagging = false) {
        let url = action;

        if (isPagging) {
            if (filter == null) {
                filter.offset = 0;
                filter.limit = PageSize.LIMIT_ITEMS_PER_PAGE_DEFAULT;
            }
        }
        let queryParams = parseFilterToString(filter);

        if (queryParams != null && queryParams !== "") {
            url = `${action}?${queryParams}`;
        }

        let response = await axios.get(url);
        return response.data;
    }

    static async load(action, filter = {}, isPagging = false) {
        let url = action;

        if (isPagging) {
            if (filter == null) {
                filter.offset = 0;
                filter.limit = PageSize.LIMIT_ITEMS_PER_PAGE_DEFAULT;
            }
        }
        let queryParams = parseFilterToString(filter);

        if (queryParams != null && queryParams !== "") {
            url = `${action}?${queryParams}`;
        }

        let response = await axios.get(url);
        return response;
    }

    static async delete(action) {
        let response = await axios.delete(action);
        return response.data;
    }

    static async patch(action, params) {
        let response = await axios.patch(action, params);
        return response.data;
    }

    /**
     * Invoke api with URL
     * @param {*} url 
     * @param {*} method 
     * @param {*} param
     * @returns
     */
    static async invokeApi(url, method, { queryParams = null, headers = null, payload = null, dataResponseOnly = true, showAlert = false }) {
        let requestURL = url;
        const headersDefault = this.makeAuthorizationHeader();

        const newRequest = {
            method: method,
            body: payload != null ? payload : null,
            headers: headers != null ? headers : headersDefault
        };
        const queryParamsStr = parseFilterToString(queryParams);

        if (queryParamsStr != null && queryParamsStr !== "") {
            requestURL = `${url}?${queryParamsStr}`;
        }

        // console.log(`request: ${requestURL}, payload: ${JSON.stringify(newRequest)}`);

        return await fetch(requestURL, newRequest)
            .then((response) => {
                if (response) {
                    return response.json().then((dataRes) => {
                        if (response.status !== HttpStatusCode.Ok) {
                            return Promise.reject(dataRes);
                        }

                        // console.log(`response -> ${JSON.stringify(dataRes)}`);

                        if (showAlert && dataRes && dataRes.message && dataRes.message !== '') {
                            showToastSuccess(`${dataRes.message}`);
                        }

                        if (dataResponseOnly && dataRes && dataRes.data) {
                            return dataRes.data;
                        }

                        return dataRes;
                    });
                } else {
                    return null;
                }
            })
            .catch(err => {
                // Do something with response error
                // console.log(`error -> ${JSON.stringify(err)}`);
                const errResponse = err || {};

                if (showAlert) {
                    if (errResponse && errResponse.message && errResponse.message !== '') {
                        showToastError(`${errResponse.message}`);
                    }
                }

                return Promise.reject(errResponse);
                // return errResponse;
            });
    }

    /**
     * Make authorization header with method (Bearer or Basic...)
     * @param {method = 'Bearer', token = ''}
     */
    static makeAuthorizationHeader(token = '', method = 'Bearer') {
        let headersDefault = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            // 'Accept': '*/*',
            'language': 'en'
        };

        let accessToken = token;

        if (token == null || token === '') {
            const tokenDetailsStr = getLocalData(LOCAL_STORAGE_KEY.TOKEN_DETAILS_KEY);
            const tokenDetails = tokenDetailsStr ? JSON.parse(tokenDetailsStr) : null;

            accessToken = tokenDetails && tokenDetails.accessToken ? tokenDetails.accessToken : '';
        }

        if (accessToken) {
            headersDefault = {
                ...headersDefault,
                Authorization: `${method} ${accessToken}`
            }
        }

        return headersDefault;
    }

    /**
     * Make authorization header form-data with method (Bearer or Basic...)
     * @param {method = 'Bearer', token = ''}
     * @returns 
     */
    static makeAuthorizationHeaderFormData(token = '', method = 'Bearer') {
        let headersDefault = {
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': '*/*',
            'language': 'en'
        };

        let accessToken = token;

        if (token == null || token === '') {
            const tokenDetailsStr = getLocalData(LOCAL_STORAGE_KEY.TOKEN_DETAILS_KEY);
            const tokenDetails = tokenDetailsStr ? JSON.parse(tokenDetailsStr) : null;

            accessToken = tokenDetails && tokenDetails.accessToken ? tokenDetails.accessToken : '';
        }

        if (accessToken) {
            headersDefault = {
                ...headersDefault,
                Authorization: `${method} ${accessToken}`
            }
        }

        return headersDefault;
    }

    /**
    * Make authorization header form-urlencoded
    * @param {method = 'Bearer', token = ''}
    * @returns 
    */
    static makeAuthorizationHeaderFormURLEncoded(token = '', method = 'Bearer') {
        let headersDefault = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };

        let accessToken = token;

        if (token == null || token === '') {
            const tokenDetailsStr = getLocalData(LOCAL_STORAGE_KEY.TOKEN_DETAILS_KEY);
            const tokenDetails = tokenDetailsStr ? JSON.parse(tokenDetailsStr) : null;

            accessToken = tokenDetails && tokenDetails.accessToken ? tokenDetails.accessToken : '';
        }

        if (accessToken) {
            headersDefault = {
                ...headersDefault,
                Authorization: `${method} ${accessToken}`
            }
        }

        return headersDefault;
    }
};
