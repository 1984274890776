/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { IMAGES, SVGICON } from '../../constant/theme';
import MainPagetitle from '../../layouts/MainPagetitle';
import { Dropdown } from 'react-bootstrap';
import {
    ActionOnRecord,
    FilterCustomers, FilterVendorCustomers, MaxItemsPerPage, MaxPagePerSection, OptionMenuDeleteItem, QueryParamsKey,
    SortByDefault, VerificationStatus, VerificationStatusList
} from '../../constant/AppConstant';
import VendorCustomerDetails from './VendorCustomerDetails';
import Preloader from '../../layouts/Preloader';
import { showToastError, showToastSuccess } from '../../../helper/helper';
import VendorsService from '../../../services/VendorsService';
import { isRoleAdmin, userDetails } from '../../../services/AuthService';
import PopupConfirmation from '../../../helper/popup-confirmation';

const VendorCustomers = () => {
    // const navigate = useNavigate();
    // const dispatch = useDispatch();
    const deleteConfirmation = useRef();
    const userLogged = userDetails();
    const [customers, setCustomers] = useState([]);
    const details = useRef();
    const [loading, setLoading] = useState(false);
    const [sortBySelected, setSortBySelected] = useState();
    const [filterSelected, setFilterSelected] = useState();
    const [keyword, setKeyword] = useState('');
    // Paging
    const [currentPage, setCurrentPage] = useState(1);
    const [currentSection, setCurrentSection] = useState(0);
    const [paging, setPaging] = useState(null);
    const [totalPagingSection, setTotalPagingSection] = useState(0);

    let query = {};

    const renderPageIndex = (totalPage) => {
        const isMoreSection = totalPagingSection > 1;
        const stepIndex = currentPage >= (currentSection * MaxPagePerSection) ? (currentSection * MaxPagePerSection) : 0;
        let pageIndex = totalPage;

        if (isMoreSection) {
            pageIndex = totalPage - stepIndex;
            pageIndex = pageIndex >= MaxPagePerSection ? MaxPagePerSection : pageIndex;
        }

        const numPage = [...Array(pageIndex + 1).keys()].slice(1)

        return numPage.map((n, i) => (
            <Link className={`paginate_button ${currentPage === (n + stepIndex) ? 'current' : ''} `} key={i}
                onClick={() => changeCPage(n + stepIndex)}>
                {n + stepIndex}
            </Link>
        ));
    };

    const sortByAction = (item) => {
        setSortBySelected(item);
        findCustomers(keyword, filterSelected, item);
    };

    const filterAction = (item) => {
        setFilterSelected(item);
        findCustomers(keyword, item, sortBySelected);
    };

    function prePage() {
        if (currentPage !== 1) {
            query[QueryParamsKey.PAGE] = currentPage - 1;
            setCurrentPage(currentPage - 1);
            findCustomers(keyword, filterSelected, sortBySelected);
            setCurrentSection((query[QueryParamsKey.PAGE] / MaxPagePerSection) ^ 0);
        }
    }

    function changeCPage(id) {
        query[QueryParamsKey.PAGE] = id;
        setCurrentPage(id);
        findCustomers(keyword, filterSelected, sortBySelected);
        setCurrentSection((query[QueryParamsKey.PAGE] / MaxPagePerSection) ^ 0);
    }

    function nextPage() {
        if (paging && paging.pageCount && currentPage !== paging.pageCount) {
            query[QueryParamsKey.PAGE] = currentPage + 1;
            setCurrentPage(currentPage + 1);
            findCustomers(keyword, filterSelected, sortBySelected);
            setCurrentSection((query[QueryParamsKey.PAGE] / MaxPagePerSection) ^ 0);
        }
    }

    const onSearchByKey = (e) => {
        if (e.key === 'Enter') {
            findCustomers(keyword, filterSelected, sortBySelected);
        }
    }

    const findCustomers = async (keyword, filter, sortBy) => {
        try {
            setLoading(true);
            query[QueryParamsKey.TAKE] = MaxItemsPerPage;
            query[QueryParamsKey.KEYWORD] = keyword ? keyword : '';
            query[QueryParamsKey.FILTER_BY] = filter ? filter.key : '';
            query[QueryParamsKey.SORT_BY] = sortBy ? sortBy.key : '';

            await VendorsService.getVendorCustomers(query)
                .then((response) => {
                    setLoading(false);

                    if (response && response.success) {
                        if (response.paging) {
                            setPaging(response.paging);

                            if (response.paging.pageCount) {
                                const totalSection = Number(response.paging.pageCount) / MaxPagePerSection;
                                setTotalPagingSection((totalSection * MaxPagePerSection) !== Number(response.paging.pageCount) ? (totalSection + 1) : totalSection);
                            }
                        }

                        setCustomers(response.data);
                    } else {
                        setCustomers([]);
                    }
                });
        } catch (err) {
            setCustomers([]);
            setLoading(false);

            if (err && err.message) {
                showToastError(err.message);
            }
        }
    };

    const getVerifyLabel = (cus) => {
        let status;

        if (cus && cus.verification) {
            for (let i = 0; i < VerificationStatusList.length; i++) {
                if (cus.verification === VerificationStatusList[i].key) {
                    status = VerificationStatusList[i];
                    break;
                }
            }
        }

        if (status) {
            return (<span><img src={status.key === VerificationStatus.APPROVED ? IMAGES.IconChecked : IMAGES.IconUnavailable} className="icon-status mx-2" alt="" />{status.title}</span>);
        }

        return '';
    };

    const isEnableDeleteCustomer = (customer) => {
        return customer != null;
    }

    useEffect(() => {
        findCustomers();
    }, []);

    /**
     * Show customer details
     * @param {*} customerId 
     */
    const showCustomerDetails = async (customerId) => {
        try {
            // setLoading(true);
            await VendorsService.getVendorCustomerDetails(customerId)
                .then((response) => {
                    // setLoading(false);
                    if (response) {
                        details.current.showDetailsModal(response);
                    } else {
                        if (response && response.message) {
                            showToastError(response.message);
                        }
                    }
                });
        } catch (error) {
            // setLoading(false);
            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    /**
     * Popup dialog confirm delete the customer
     * @param {*} customer
     */
    const showDialogDelete = (customer) => {
        const title = `Delete Customer: ${customer.representativeName} (${customer.customerCode})`;
        const message = `Are you sure you want to delete this customer?`;

        deleteConfirmation.current.showModal(customer, { title: title, message: message });
    }

    /**
     * Menu option
     * @param {*} customer 
     * @param {*} status 
     */
    const chooseOptionStatus = async (customer, status) => {
        try {
            if (status === ActionOnRecord.DELETE) {
                showDialogDelete(customer);
            }
        } catch (error) {
            setLoading(false);

            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    /**
     * Handle delete customer
     * @param {*} customer 
     */
    const handleDeleteCustomer = async (customer) => {
        if (customer == null || customer.id == null || customer.id === '')
            return false;

        try {
            setLoading(true);
            await VendorsService.deteteCustomerById(customer.id)
                .then((response) => {
                    setLoading(false);

                    if (response) {
                        // Reload data
                        findCustomers(keyword, filterSelected, sortBySelected);

                        if (response.message) {
                            showToastSuccess(response.message);
                        }
                    }
                });
        } catch (error) {
            setLoading(false);

            if (error && error.message) {
                showToastError(error.message);
            }
        }
    }

    return (
        <>
            <MainPagetitle mainTitle="Customer Management" pageTitle={'List'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                    <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                        {/* <h4 className="heading mb-0">Customers</h4> */}
                                        <div className='col-xl-4 col-lg-5 col-md-6'>
                                            <div className="input-group search-area">
                                                <span className="input-group-text rounded-0">
                                                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="8.78605" cy="8.78605" r="8.23951" stroke="grey" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M14.5168 14.9447L17.7471 18.1667" stroke="grey" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </span>
                                                <input type="text" className="form-control rounded-0" placeholder="Search customer . . ." onKeyDown={onSearchByKey} onChange={(e) => setKeyword(e.target.value)} />
                                            </div>
                                        </div>

                                        <div>
                                            <span className='d-inline-block fs-13 me-2'>Filter</span>
                                            <span className='d-inline-block me-3'>
                                                <Dropdown className="task-dropdown-2">
                                                    <Dropdown.Toggle as="div">{filterSelected ? filterSelected.title : 'Filter'}</Dropdown.Toggle>
                                                    <Dropdown.Menu className='task-drop-menu'>
                                                        {FilterVendorCustomers.map((itemFilter, index) => (
                                                            <Dropdown.Item onClick={() => filterAction(itemFilter)} key={index} className={itemFilter.color != null && itemFilter.color !== '' ? itemFilter.color : ''}>{itemFilter.title}</Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </span>
                                            <span className='d-inline-block fs-13 me-2'>Sort by</span>
                                            <span className='d-inline-block me-3'>
                                                <Dropdown className="task-dropdown-2">
                                                    <Dropdown.Toggle as="div">{sortBySelected ? sortBySelected.title : 'Sort by'}</Dropdown.Toggle>
                                                    <Dropdown.Menu className='task-drop-menu'>
                                                        {SortByDefault.map((itemSortBy, index) => (
                                                            <Dropdown.Item onClick={() => sortByAction(itemSortBy)} key={index}>{itemSortBy.title}</Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </span>
                                            <Link to={{
                                                pathname: "/import-vendor-customers",
                                                state: { fromDashboard: true }
                                            }} className="btn btn-primary btn-sm me-3">+ Import Customers</Link>
                                            <Link to={"/add-vendor-customer"} className="btn btn-primary btn-sm">+ Add Customer</Link>
                                        </div>
                                    </div>
                                    <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                                        <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Customer Code</th>
                                                    <th>Email</th>
                                                    <th>Contact</th>
                                                    <th>Company Name</th>
                                                    <th>Company Reg No.</th>
                                                    <th>Tax ID</th>
                                                    <th>Verification</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {customers && customers.map((item, index) => (
                                                    <tr key={index}>
                                                        <td><span>{index + 1}</span></td>
                                                        <td>
                                                            <span className='text-black auto-break-word' onClick={() => showCustomerDetails(item.id)}>{item.representativeName}</span>
                                                        </td>
                                                        <td><span className='text-black' onClick={() => showCustomerDetails(item.id)}>{item.customerCode ? item.customerCode : '-'}</span></td>
                                                        <td><span className="text-info">{item.email ? item.email : '-'}</span></td>
                                                        <td><span>{item.phoneNumber}</span></td>
                                                        <td><span className='auto-break-word'>{item.profileInfo ? item.profileInfo.companyName : '-'}</span></td>
                                                        <td><span>{item.businessLicenseNo ? item.businessLicenseNo : '-'}</span></td>
                                                        <td><span>{item.billingInfo ? item.billingInfo.taxCode : '-'}</span></td>
                                                        <td><span>{getVerifyLabel(item)}</span></td>
                                                        <td>
                                                            <span>
                                                                <Link to={{
                                                                    pathname: "/edit-vendor-customer",
                                                                    search: `?${QueryParamsKey.CUSTOMER_ID}=${item.id}`,
                                                                    state: { fromDashboard: true }
                                                                }}><img src={IMAGES.IconEdit} className="avatar avatar-sm" alt="" /></Link>
                                                                <label onClick={() => showCustomerDetails(item.id)}><img src={IMAGES.IconView} className="avatar avatar-sm mx-2" alt="" /></label>
                                                                {isEnableDeleteCustomer(item) && <>
                                                                    <Dropdown className="dropdown custom-dropdown mb-0">
                                                                        <Dropdown.Toggle
                                                                            className="btn sharp btn-primary tp-btn i-false"
                                                                            data-toggle="dropdown">{SVGICON.MenuDot}
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                                                                            {OptionMenuDeleteItem.map((st, indexStatus) => (<Dropdown.Item key={indexStatus} className={`dropdown-item ${st.key === ActionOnRecord.DELETE ? 'text-danger' : ''}`} onClick={(e) => {
                                                                                e.preventDefault();
                                                                                chooseOptionStatus(item, st.key);
                                                                            }}>{st.title}</Dropdown.Item>))}
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </>}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>

                                        </table>
                                        {customers && customers.length > 0 ? <>
                                            <div className="d-sm-flex text-center justify-content-between align-items-center">
                                                <div className='dataTables_info'>
                                                    Showing {customers ? customers.length : 0} of {paging && paging.total ? paging.total : 0} entries
                                                </div>
                                                {paging && paging.pageCount && <>
                                                    <div className="dataTables_paginate paging_simple_numbers justify-content-center" id="example2_paginate">
                                                        {currentPage !== 1 && <>
                                                            <Link className="paginate_button previous disabled" to="#" onClick={prePage}>
                                                                <i className="fa-solid fa-angle-left" />
                                                            </Link>
                                                        </>}
                                                        <span>{renderPageIndex(paging.pageCount)}</span>
                                                        {currentPage !== paging.pageCount && <>
                                                            <Link className="paginate_button next" to="#" onClick={nextPage}>
                                                                <i className="fa-solid fa-angle-right" />
                                                            </Link>
                                                        </>}
                                                    </div>
                                                </>}
                                            </div>
                                        </> : <><label className='m-3'>No data</label></>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading && (<div className="dialog-blur">{<Preloader />}</div>)}
            <VendorCustomerDetails ref={details} title="Customer Details" />
            <PopupConfirmation ref={deleteConfirmation} onConfirm={handleDeleteCustomer} />
        </>
    );
};

export default VendorCustomers;