export const VendorIdDefault = process.env.REACT_APP_API_VENDOR_DEFAULT;
export const MaxItemsPerPage = 100;
export const MaxPagePerSection = 25;
export const ACCEPT_BUSINESS_LICENSE_FILE = '.pdf, .jpeg, .jpg, .png';
export const ACCEPT_ONLY_IMAGE_FILE = '.jpeg, .jpg, .png, .jxl'; // 'image/*';
export const ACCEPT_ONLY_CSV_FILE = '.csv, .xls, .xlsx';
export const DOCS_FILE_EXTENSION = ['pdf', 'png', 'jpeg', 'jpg', 'jxl'];    // 'doc', 'docx'
export const MIME_TYPE_SUPPORTED = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg', 'image/jxl'];

export const QueryParamsKey = {
    PAGE: 'page',
    OFFSET: 'offset',
    TAKE: 'take',
    IS_PAGING: 'is_paging',
    KEYWORD: 'keyword',
    ID: 'id',
    FILTER_BY: 'filter_by',
    SORT_BY: 'sort_by',
    CUSTOMER_ID: 'cus_id',
    UCD_CODE: 'ucd_code',
    VENDOR_ID: 'vendor_id',
    ACCESS_TOKEN: 'access_token',
    FROM_DATE: 'from_date',
    TO_DATE: 'to_date',
    CHUNK_FILES: 'chunk_files',
    ACTION_TYPE: 'action_type',
    CONTACT_NO: 'contact_no'
};

export const ApiMethod = {
    Auth: {
        GetToken: 'api/1.0/auth/token',
        Login: 'api/1.0/auth/login',
        Logout: 'api/1.0/auth/logout',
        RequestOTPResetPassword: 'api/1.0/auth/request_otp/reset_password',
        ConfirmResetPassword: 'api/1.0/auth/confirm/reset_password',
    },
    Accounts: {
        ListAccounts: 'api/1.0/accounts',
        ListAccountsByVendorId: 'api/1.0/accounts/by_vendor',
        NewAccount: 'api/1.0/accounts',
        CreateListAccount: 'api/1.0/accounts/create_list',
        UpdateAccount: 'api/1.0/accounts',
        UpdateAccountStatus: 'api/1.0/accounts/update_status',
        GenerateNewApiKey: 'api/1.0/accounts/generate_api_key',
        Details: 'api/1.0/accounts/details',
        Profile: 'api/1.0/accounts/profile',
        UpdateProfile: 'api/1.0/accounts/profile/update_info',
        DeleteById: 'api/1.0/accounts/delete'
    },
    Customers: {
        NewCustomer: 'api/1.0/customers',
        UpdateCustomer: 'api/1.0/customers',
        UpdateStatus: 'api/1.0/customers/update_status',
        ListCustomers: 'api/1.0/customers',
        Details: 'api/1.0/customers/details',
        DetailsByContact: 'api/1.0/customers/details_by_contact?contact_no=%s',
        Verified: 'api/1.0/customers/verified',
        SyncFailingData: 'api/1.0/customers/sync_failing_data',
        RejectChangesCustomerInfo: 'api/1.0/customers/verify_info/%s/reject',
        DeleteById: 'api/1.0/customers/delete',
        RemoveVietPOMIdentity: 'api/1.0/customers/vietpom/%s/remove_identity/%s',
        AddVietPOMIdentity: 'api/1.0/customers/vietpom/%s/add_identity',
        FindCustomerFromVendor: 'api/1.0/customers/vendor/find_customer?vendor_id=%s&customer_code=%s&phone_number=%s',
        ImportVendorCustomersFromFile: 'api/1.0/customers/vendor/%s/import_customers',
        UpdateCustomersFromFile: 'api/1.0/customers/%s/update_customers_from_file',
        // Sub-users
        ListSubUsers: 'api/1.0/customers/%s/sub_users',
        CreateSubUser: 'api/1.0/customers/%s/sub_users',
        UpdateSubUser: 'api/1.0/customers/%s/sub_users/%s',
        DeleteSubUser: 'api/1.0/customers/%s/sub_users/%s',
    },
    Vendors: {
        ListVendors: 'api/1.0/vendors',
        NewVendor: 'api/1.0/vendors',
        UpdateVendor: 'api/1.0/vendors',
        Details: 'api/1.0/vendors/details',
        UpdateStatus: 'api/1.0/vendors/update_status',
        DeleteById: 'api/1.0/vendors/delete',
        ListCustomers: 'api/1.0/vendors/customers',
        GetVendorCustomerDetails: 'api/1.0/vendors/customers/details',
        UpdateVendorCustomer: 'api/1.0/vendors/customers/update_customer',
        DeleteVendorCustomerById: 'api/1.0/vendors/customers/delete',
    },
    Report: {
        TemplateFileVendorCustomers: 'api/1.0/report/template_files/vendor_customers',
        TemplateFileUpdateVendorCustomers: 'api/1.0/report/template_files/update_vendor_customers',
        GetDashboardSummary: 'api/1.0/report/dashboard/summary',
        GetHistoryImportCustomers: 'api/1.0/report/history/import_customers',
        CountCustomersImportFailed: 'api/1.0/report/history/count_customers_import_failed',
        ExportCustomersImportFailed: 'api/1.0/report/history/export_customers_import_failed',
        ExportUnifiedCustomersToFile: 'api/1.0/report/export_unified_customers'
    },
    Assets: {
        GetFile: 'assets/files?key_file=',
        GetDocumentFile: 'assets/get_document?key_file='
    },
    Settings: {
        ListConfiguration: 'api/1.0/settings',
        NewConfiguration: 'api/1.0/settings',
        CongfigurationDetails: 'api/1.0/settings/details',
        UpdateConfiguration: 'api/1.0/settings',
        UpdateConfigurationStatus: 'api/1.0/settings/%s/update_status',
        DeleteById: 'api/1.0/settings'
    },
};

export const CountryCodes = {
    VIETNAM_COUNTRY_CODE: '+84',
    SINGAPORE_COUNTRY_CODE: '+65'
};

export const CountryAvailable = [
    { name: 'Viet Nam', code: CountryCodes.VIETNAM_COUNTRY_CODE, isoCode: 'VN' },
    { name: 'Singapore', code: CountryCodes.SINGAPORE_COUNTRY_CODE, isoCode: 'SG' }
];

/**
 * List all special vendor
 */
export const SpecialVendors = {
    VIETPOM: VendorIdDefault,
    HOANG_DUC: 'hoangduc-vn'
};

/**
 * Roles.
 */
export const Roles = {
    SUPER_ADMIN: "SA",
    ADMIN: "A",
    VENDOR_ADMIN: "VA",
    DOCTOR: "DR",
    GetRoleName(roles) {
        switch (roles) {
            case Roles.SUPER_ADMIN:
                return 'Super Admin';

            case Roles.ADMIN:   // UCD Admin
                return 'Admin';

            case Roles.VENDOR_ADMIN:
                return 'Vendor Admin';

            case Roles.DOCTOR:
                return 'Doctor';

            default:
                return roles;
        }
    }
};

/**
 * Roles available.
 */
export const RolesAvailable = [
    { name: 'Admin', key: Roles.ADMIN },
    { name: 'Vendor Admin', key: Roles.VENDOR_ADMIN }
];

/**
 * Roles available (Only for super admin).
 */
export const SuperAdminRolesAvailable = [
    { name: 'Super Admin', key: Roles.SUPER_ADMIN },
    { name: 'Admin', key: Roles.ADMIN },
    { name: 'Vendor Admin', key: Roles.VENDOR_ADMIN }
];

/**
 * Forensic group default
 */
export const UserGroupDefault = {
    PHARMA_COMPANY: 'Pharma Company',
    PHARMACY: 'Pharmacy',
    CLINIC: 'Clinic',
    DOCTOR: 'Doctor',
    NON_PHARMACY_LICENSE: 'Non-Pharmacy license',
    ListAvailable() {
        return [
            {
                keyword: UserGroupDefault.PHARMA_COMPANY,
                name: 'Công ty dược'
            },
            {
                keyword: UserGroupDefault.PHARMACY,
                name: 'Quầy thuốc / Nhà thuốc'
            },
            {
                keyword: UserGroupDefault.CLINIC,
                name: 'Nhà thuốc phòng khám'
            },
            {
                keyword: UserGroupDefault.DOCTOR,
                name: 'Bác sĩ / Phòng mạch tư'
            },
            {
                keyword: UserGroupDefault.NON_PHARMACY_LICENSE,
                name: 'Khác (Nhà thuốc Đông Y, thẩm mỹ viện, spa, siêu thị,...)',
                subGroups: SubForensicGroupDefault.ListSubForensicForNonPharmacy()
            }
        ];
    },
    GetName(key) {
        let result = '';
        const allItems = UserGroupDefault.ListAvailable();

        for (let i = 0; i < allItems.length; i++) {
            if (allItems[i].keyword === key) {
                result = allItems[i].name;
                break;
            }
        }

        return result;
    }
};

/**
 * Sub forensic group default
 */
export const SubForensicGroupDefault = {
    // For Non-Pharmacy license
    COSMETIC_HOSPITAL: 'Cosmetic Hospital',
    TRADITIONAL_MEDICINE_PHARMACY: 'Traditional Medicine Pharmacy',
    BEAUTY_SALON: 'Beauty Salon',
    SPA: 'Spa',
    SUPERMARKET: 'Supermarket',
    RETAILER: 'Retailer',
    ListSubForensicForNonPharmacy() {
        return [
            {
                keyword: SubForensicGroupDefault.COSMETIC_HOSPITAL,
                name: 'Bệnh viện thẩm mỹ'
            },
            {
                keyword: SubForensicGroupDefault.TRADITIONAL_MEDICINE_PHARMACY,
                name: 'Nhà thuốc Đông Y'
            },
            {
                keyword: SubForensicGroupDefault.BEAUTY_SALON,
                name: 'Thẩm mỹ viện'
            },
            {
                keyword: SubForensicGroupDefault.SPA,
                name: 'Spa'
            },
            {
                keyword: SubForensicGroupDefault.SUPERMARKET,
                name: 'Siêu thị'
            },
            {
                keyword: SubForensicGroupDefault.RETAILER,
                name: 'Tiểu thương'
            }
        ];
    },
    GetName(key, sources = []) {
        let result = '';

        if (sources != null && sources.length > 0) {
            for (let i = 0; i < sources.length; i++) {
                if (sources[i].keyword === key) {
                    result = sources[i].name;
                    break;
                }
            }
        }

        return result;
    }
};

// Required for Doctor
export const SpecialtyDefault = {
    GENERAL_PRACTICE: 'General Practice',
    AESTHETIC: 'Aesthetic',
    CARDIOLOGY: 'Cardiology',
    DENTAL: 'Dental',
    ENDOCRINE: 'Endocrine',
    GASTRO: 'Gastro',
    RENAL: 'Renal',
    DERMATOLOGY: 'Dermatology',
    PSYCHIATRY: 'Psychiatry',
    PEDIATRIC: 'Pediatric',
    ORTHOPEDICS: 'Orthopedics',
    OBS_GYN: 'Obs & Gyn',
    ENT: 'ENT',
    EYE_SPECIALIST: 'Eye Specialist',
    OTHER_SPECIALTY: 'Other Specialty',

    ListAvailable() {
        return [
            {
                keyword: SpecialtyDefault.GENERAL_PRACTICE,
                name: 'Đa khoa'
            },
            {
                keyword: SpecialtyDefault.AESTHETIC,
                name: 'Thẩm mỹ'
            },
            {
                keyword: SpecialtyDefault.CARDIOLOGY,
                name: 'Tim mạch'
            },
            {
                keyword: SpecialtyDefault.DENTAL,
                name: 'Răng hàm mặt'
            },
            {
                keyword: SpecialtyDefault.ENDOCRINE,
                name: 'Nội tiết'
            },
            {
                keyword: SpecialtyDefault.GASTRO,
                name: 'Tiêu hóa'
            },
            {
                keyword: SpecialtyDefault.RENAL,
                name: 'Thận tiết niệu'
            }, {
                keyword: SpecialtyDefault.DERMATOLOGY,
                name: 'Da liễu'
            },
            {
                keyword: SpecialtyDefault.PSYCHIATRY,
                name: 'Tâm thần'
            },
            {
                keyword: SpecialtyDefault.PEDIATRIC,
                name: 'Khoa nhi'
            },
            {
                keyword: SpecialtyDefault.ORTHOPEDICS,
                name: 'Chấn thương chỉnh hình'
            },
            {
                keyword: SpecialtyDefault.OBS_GYN,
                name: 'Sản phụ khoa'
            },
            {
                keyword: SpecialtyDefault.ENT,
                name: 'Tai mũi họng'
            },
            {
                keyword: SpecialtyDefault.EYE_SPECIALIST,
                name: 'Khoa mắt'
            },
            {
                keyword: SpecialtyDefault.OTHER_SPECIALTY,
                name: 'Các chuyên khoa khác'
            }
        ];
    },
    GetName(key) {
        let result = '';
        const allItems = SpecialtyDefault.ListAvailable();

        for (let i = 0; i < allItems.length; i++) {
            if (allItems[i].keyword === key) {
                result = allItems[i].name;
                break;
            }
        }

        return result;
    }
};

/**
 * Regions group default
 */
export const RegionsGroupDefault = {
    NORTH: 'MIEN BAC',     // 'North',
    CENTRAL: 'MIEN TRUNG', // 'Central',
    SOUTH: 'MIEN NAM',     // 'South',
    GetName(key) {
        let result = '';
        const allItems = RegionsGroupDefault.ListAvailable();

        for (let i = 0; i < allItems.length; i++) {
            if (allItems[i].keyword === key) {
                result = allItems[i].name;
                break;
            }
        }

        return result;
    },
    ListAvailable() {
        return [
            {
                keyword: RegionsGroupDefault.NORTH,
                name: 'Miền Bắc'
            },
            {
                keyword: RegionsGroupDefault.CENTRAL,
                name: 'Miền Trung'
            },
            {
                keyword: RegionsGroupDefault.SOUTH,
                name: 'Miền Nam'
            }
        ];
    }
};

export const LocationFormat = {
    DISTRICT_NUMBER_PREFIX_VN: 'Quận',
    WARD_NUMBER_PREFIX_VN: 'Phường',
};

/**
 * Location priority default
 */
export const LocationPriorityDefault = {
    VN_HCM: {
        CodeCity: 79,
        NameCity: 'Hồ Chí Minh',
    },
    VN_HN: {
        CodeCity: 1,
        NameCity: 'Hà Nội',
    },
    ListAvailable() {
        return [
            LocationPriorityDefault.VN_HCM,
            LocationPriorityDefault.VN_HN
        ];
    }
};

export const ActionOnRecord = {
    DETAILS: 'details',
    EDIT: 'edit',
    DELETE: 'delete',
    SYNC_FAILING_DATA: 'sync_failing_data',
    ON_CREATE: 'on_create',
    ON_UPDATE: 'on_update'
};

export const VerificationStatus = {
    APPROVED: "A",
    PENDING: "P"
};

export const UserStatus = {
    ACTIVE: 'A',
    DISABLED: 'D'
};

export const ConfigStatus = {
    ACTIVE: 'A',
    DISABLED: 'D'
};

export const ConfigStatusList = [
    { title: 'Active', key: ConfigStatus.ACTIVE, color: '' },
    { title: 'Disabled', key: ConfigStatus.DISABLED, color: 'text-disabled' }
];

export const UserStatusList = [
    { title: 'Active', key: UserStatus.ACTIVE, color: '' },
    { title: 'Disabled', key: UserStatus.DISABLED, color: 'text-disabled' }
];

export const VerificationStatusList = [
    { title: 'Approved', key: VerificationStatus.APPROVED },
    { title: 'Pending', key: VerificationStatus.PENDING }
];

// Admin Management
export const AccountTabKeys = {
    BASIC_INFORMATION: 'basic_information',
    VENDOR_INFORMATION: 'vendor_information'
};

// Customer management
export const CustomerTabKeys = {
    BASIC_INFORMATION: 'basic_information',
    SHIPPING_ADDRESS: 'shipping_address',
    BILLING_ADDRESS: 'billing_address',
    REF_ID: 'ref_id',
    SUB_USERS: 'sub_users',
    USER_INFORMATION: 'user_information'
};

// Vendor Management
export const VendorTabKeys = {
    BASIC_INFORMATION: 'basic_information',
    ADMIN_ACCOUNTS: 'admin_accounts'
};

export const FilterKeys = {
    ALL: 'all',
    REQUEST_KYC: 'request_kyc',
    FAILING_SENDING_DATA: 'failing_sending_data',
    BY_CREATED_DATE: 'by_created_date',
    BY_APPROVAL_DATE: 'by_approval_date',
    ACTIVE: 'active',
    INACTIVE: 'inactive'
};

export const SortByRequestedDateDefault = [
    { title: 'Requested date', key: 'requested_date' }
];

export const SortByApprovedDateDefault = [
    { title: 'Approved date', key: 'approved_date' }
];

export const SortByDefault = [
    { title: 'Alphabetical', key: 'alphabetical' },
    { title: 'Status', key: 'status' }
];

export const FilterDefault = [
    { title: 'All', key: FilterKeys.ALL }
];

export const FilterByCreatedDate = [
    { title: 'By created date', key: FilterKeys.BY_CREATED_DATE }
];

export const FilterByApprovalDate = [
    { title: 'By approval date', key: FilterKeys.BY_APPROVAL_DATE }
];

export const FilterCustomerRequestKYC = [
    { title: 'Request KYC', key: FilterKeys.REQUEST_KYC }
];

export const FilterCustomersError = [
    { title: 'Failing sending data', key: FilterKeys.FAILING_SENDING_DATA, color: 'text-danger' }
];

// Filter unified customers
export const FilterCustomers = [
    ...FilterDefault,
    { title: 'Active', key: FilterKeys.ACTIVE },
    { title: 'Inactive', key: FilterKeys.INACTIVE, color: 'text-disabled' },
    { title: 'Approved', key: VerificationStatus.APPROVED },
    { title: 'Pending', key: VerificationStatus.PENDING }
];

// Filter vendor customers
export const FilterVendorCustomers = [
    ...FilterDefault,
    { title: 'Approved', key: VerificationStatus.APPROVED },
    { title: 'Pending', key: VerificationStatus.PENDING }
];

export const FilterVendors = [
    ...FilterDefault,
    { title: 'Active', key: UserStatus.ACTIVE },
    { title: 'Disabled', key: UserStatus.DISABLED }
];

export const TabsCustomerNonRefIDOnlyView = [
    {
        name: "Basic Information",
        key: CustomerTabKeys.BASIC_INFORMATION
    },
    {
        name: "Billing Address",
        key: CustomerTabKeys.BILLING_ADDRESS
    },
    {
        name: "Shipping Address",
        key: CustomerTabKeys.SHIPPING_ADDRESS
    }
];

export const TabsCustomerNonRefID = [
    {
        name: "Basic Information",
        key: CustomerTabKeys.BASIC_INFORMATION,
        segments: [
            {
                name: "Billing Address",
                key: CustomerTabKeys.BILLING_ADDRESS
            },
            {
                name: "Shipping Address",
                key: CustomerTabKeys.SHIPPING_ADDRESS
            }
        ]
    }
    // {
    //     name: "Billing Address",
    //     key: CustomerTabKeys.BILLING_ADDRESS
    // },
    // {
    //     name: "Shipping Address",
    //     key: CustomerTabKeys.SHIPPING_ADDRESS
    // }
];

export const TabsCustomerRefID = [
    {
        name: "Ref. ID",
        key: CustomerTabKeys.REF_ID
    }
];

export const AllTabsCustomerInfo = [
    ...TabsCustomerNonRefID,
    {
        name: "Ref. ID",
        key: CustomerTabKeys.REF_ID
    }
];

export const AllTabsCustomerForVendor = [
    ...TabsCustomerNonRefID
];

export const AllTabsVendorDetails = [
    {
        name: "Basic Information",
        key: VendorTabKeys.BASIC_INFORMATION
    },
    {
        name: "Admin Accounts",
        key: VendorTabKeys.ADMIN_ACCOUNTS
    }
];

export const AllTabsVendorAdminDetails = [
    {
        name: "Basic Information",
        key: AccountTabKeys.BASIC_INFORMATION
    },
    {
        name: "Vendor Information",
        key: AccountTabKeys.VENDOR_INFORMATION
    }
];

export const AllTabsUserDetails = [
    {
        name: "Basic Information",
        key: AccountTabKeys.BASIC_INFORMATION
    }
];

export const TabsCustomerAccounts = [
    {
        name: "Sub-users",
        key: CustomerTabKeys.SUB_USERS
    }
];

export const AllTabsSubUser = [
    {
        name: "User Information",
        key: CustomerTabKeys.USER_INFORMATION
    }
];

export const OptionMenuDeleteItem = [
    { title: 'Delete', key: ActionOnRecord.DELETE, color: 'text-danger' },
];

export const OptionMenuSyncFailingDataItem = [
    { title: 'Sync failing data', key: ActionOnRecord.SYNC_FAILING_DATA, color: 'text-danger' },
];

export const RoleOfSubUserDefault = {
    // Doctor, Purchaser, Pharmacist, Nurse, Finance
    DOCTOR: 'Doctor',
    PURCHASER: 'Purchaser',
    PHARMACIST: 'Pharmacist',
    NURSE: 'Nurse',
    FINANCE: 'Finance',
    GetName(key) {
        let result = '';
        const allItems = RoleOfSubUserDefault.ListAvailable();

        for (let i = 0; i < allItems.length; i++) {
            if (allItems[i].keyword === key) {
                result = allItems[i].name;
                break;
            }
        }

        return result;
    },
    ListAvailable() {
        return [
            {
                keyword: RoleOfSubUserDefault.DOCTOR,
                name: 'Doctor'
            },
            {
                keyword: RoleOfSubUserDefault.PURCHASER,
                name: 'Purchaser'
            },
            {
                keyword: RoleOfSubUserDefault.PHARMACIST,
                name: 'Pharmacist'
            },
            {
                keyword: RoleOfSubUserDefault.NURSE,
                name: 'Nurse'
            },
            {
                keyword: RoleOfSubUserDefault.FINANCE,
                name: 'Finance'
            }
        ];
    }
};

export const OptionMenuSubUser = [
    { title: 'Edit', key: ActionOnRecord.EDIT, color: '' },
    ...OptionMenuDeleteItem
];

export const ConfigType = {
    SYSTEMS: 'systems',
    PROTECT_REGISTRATION_FORM: 'protect_registration_form'
};

export const ConfigTypes = {
    GetName(key) {
        let result = '';
        const allItems = ConfigTypes.ListAvailable();

        for (let i = 0; i < allItems.length; i++) {
            if (allItems[i].key === key) {
                result = allItems[i].title;
                break;
            }
        }

        return result;
    },
    ListAvailable() {
        return [
            { title: 'Password Protect Registration Form', key: ConfigType.PROTECT_REGISTRATION_FORM, default: true }
        ];
    }
};

export const OptionMenuConfig = [
    ...ConfigStatusList,
    ...OptionMenuDeleteItem
];

export const RangeRecordsExportToFile = [2000, 3000, 4000, 5000];