/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { IMAGES, SVGICON } from '../../constant/theme';
import MainPagetitle from '../../layouts/MainPagetitle';
import CustomersService from '../../../services/CustomersService';
import { Dropdown } from 'react-bootstrap';
import {
    ActionOnRecord, FilterByApprovalDate, FilterCustomerRequestKYC, FilterCustomers, FilterCustomersError, FilterKeys, MaxItemsPerPage,
    MaxPagePerSection, OptionMenuDeleteItem, OptionMenuSyncFailingDataItem, QueryParamsKey, SortByDefault,
    SortByRequestedDateDefault, UserStatus, UserStatusList, VerificationStatus, VerificationStatusList
} from '../../constant/AppConstant';
import CustomerDetails from './CustomerDetails';
import Preloader from '../../layouts/Preloader';
import { getMaxDateFilter, showToastError, showToastSuccess, showToastWarning } from '../../../helper/helper';
import VendorsService from '../../../services/VendorsService';
import { isRoleAdmin, isRoleSuperAdmin, userDetails } from '../../../services/AuthService';
import PopupConfirmation from '../../../helper/popup-confirmation';
import { TextApp, TextKey } from '../../../assets/text-app';
import ReportService from '../../../services/ReportService';
import ExportCustomers from './ExportCustomers';

const Customers = () => {
    // const navigate = useNavigate();
    // const dispatch = useDispatch();
    const isAdmin = isRoleAdmin();
    const isSuperAdmin = isRoleSuperAdmin();
    const userLogged = userDetails();
    const [vendors, setVendors] = useState([]);
    const [customers, setCustomers] = useState([]);
    const details = useRef();
    const exportPage = useRef();
    const deleteConfirmation = useRef();
    const [loading, setLoading] = useState(false);
    const [sortBySelected, setSortBySelected] = useState();
    const [filterSelected, setFilterSelected] = useState();
    const [keyword, setKeyword] = useState('');
    const [filterCustomers, setFilterCustomers] = useState(FilterCustomers);
    const [sortByAvailable, setSortByAvailable] = useState([]);
    const [filterByDate, setFilterByDate] = useState({
        fromDate: '',
        toDate: ''
    });
    // Paging
    const [currentPage, setCurrentPage] = useState(1);
    const [currentSection, setCurrentSection] = useState(0);
    const [paging, setPaging] = useState(null);
    const [totalPagingSection, setTotalPagingSection] = useState(0);
    const [menuOptions, setMenuOptions] = useState(UserStatusList);
    const [refreshPage, setRefreshPage] = useState(false);
    // Query params
    let query = {};

    const renderPageIndex = (totalPage) => {
        const isMoreSection = totalPagingSection > 1;
        const stepIndex = currentPage >= (currentSection * MaxPagePerSection) ? (currentSection * MaxPagePerSection) : 0;
        let pageIndex = totalPage;

        if (isMoreSection) {
            pageIndex = totalPage - stepIndex;
            pageIndex = pageIndex >= MaxPagePerSection ? MaxPagePerSection : pageIndex;
        }

        const numPage = [...Array(pageIndex + 1).keys()].slice(1)

        return numPage.map((n, i) => (
            <Link className={`paginate_button ${currentPage === (n + stepIndex) ? 'current' : ''} `} key={i}
                onClick={() => changeCPage(n + stepIndex)}>
                {n + stepIndex}
            </Link>
        ));
    };

    const onChangeFilterFromDate = e => {
        filterByDate.fromDate = e.target.value;

        setRefreshPage(!refreshPage);
        findCustomers(keyword, filterSelected, sortBySelected, filterByDate.fromDate, filterByDate.toDate);
    };

    const onChangeFilterToDate = e => {
        filterByDate.toDate = e.target.value;
        setRefreshPage(!refreshPage);

        findCustomers(keyword, filterSelected, sortBySelected, filterByDate.fromDate, filterByDate.toDate);
    };

    const sortByAction = (item) => {
        setSortBySelected(item);
        findCustomers(keyword, filterSelected, item);
    };

    const filterAction = (item) => {
        setFilterSelected(item);
        findCustomers(keyword, item, sortBySelected);
    };

    function prePage() {
        if (currentPage !== 1) {
            query[QueryParamsKey.PAGE] = currentPage - 1;
            setCurrentPage(currentPage - 1);
            findCustomers(keyword, filterSelected, sortBySelected);
            setCurrentSection((query[QueryParamsKey.PAGE] / MaxPagePerSection) ^ 0);
        }
    }

    function changeCPage(id) {
        query[QueryParamsKey.PAGE] = id;
        setCurrentPage(id);
        findCustomers(keyword, filterSelected, sortBySelected);
        setCurrentSection((query[QueryParamsKey.PAGE] / MaxPagePerSection) ^ 0);
    }

    function nextPage() {
        if (paging && paging.pageCount && currentPage !== paging.pageCount) {
            query[QueryParamsKey.PAGE] = currentPage + 1;
            setCurrentPage(currentPage + 1);
            findCustomers(keyword, filterSelected, sortBySelected);
            setCurrentSection((query[QueryParamsKey.PAGE] / MaxPagePerSection) ^ 0);
        }
    }

    const onSearchByKey = (e) => {
        if (e.key === 'Enter') {
            findCustomers(keyword, filterSelected, sortBySelected);
        }
    }

    const isEnableOptionSyncFailingData = () => {
        return false;
        // let result = false;

        // if (filterSelected && filterSelected.key === FilterKeys.FAILING_SENDING_DATA && customers && customers.length > 0) {
        //     result = true;
        // }

        // return result;
    }

    const findCustomers = async (keyword, filter, sortBy, fromDate, toDate) => {
        try {
            setLoading(true);
            query[QueryParamsKey.TAKE] = MaxItemsPerPage;
            query[QueryParamsKey.KEYWORD] = keyword ? keyword : '';
            query[QueryParamsKey.FILTER_BY] = filter ? filter.key : '';
            query[QueryParamsKey.SORT_BY] = sortBy ? sortBy.key : '';
            query[QueryParamsKey.FROM_DATE] = fromDate ? fromDate : (filterByDate && filterByDate.fromDate ? filterByDate.fromDate : '');
            query[QueryParamsKey.TO_DATE] = toDate ? toDate : (filterByDate && filterByDate.toDate ? filterByDate.toDate : '');

            await CustomersService.getCustomers(query)
                .then((response) => {
                    setLoading(false);

                    if (response && response.success) {
                        if (response.paging) {
                            setPaging(response.paging);

                            if (response.paging.pageCount) {
                                const totalSection = Number(response.paging.pageCount) / MaxPagePerSection;
                                setTotalPagingSection((totalSection * MaxPagePerSection) !== Number(response.paging.pageCount) ? (totalSection + 1) : totalSection);
                            }
                        }

                        setCustomers(response.data);
                    } else {
                        setCustomers([]);
                    }
                });
        } catch (err) {
            setCustomers([]);
            setLoading(false);

            if (err && err.message) {
                showToastError(err.message);
            }
        }
    };

    // Load all vendors available
    const findVendors = async () => {
        // Load all vendors available
        if (!isAdmin)
            return;

        try {
            const allVendors = await VendorsService.findVendors({}, false, true);
            setVendors(allVendors);
        } catch (error) {
            // console.warn(error);
        }
    };

    const getStatusLabel = (ven) => {
        let status;

        if (ven && ven.status) {
            for (let i = 0; i < UserStatusList.length; i++) {
                if (ven.status === UserStatusList[i].key) {
                    status = UserStatusList[i];
                    break;
                }
            }
        }

        if (status) {
            return (<span className='pe-0 c-status'><i
                className=
                {`fa-solid fa-circle text-${status.key === UserStatus.ACTIVE ? 'available' :
                    status.key === UserStatus.DISABLED ? 'disabled' : 'primary'}`}
            />{status.title}</span>);
        }

        return '-';
    };

    const getVerifyLabel = (cus) => {
        let status;

        if (cus && cus.verification) {
            for (let i = 0; i < VerificationStatusList.length; i++) {
                if (cus.verification === VerificationStatusList[i].key) {
                    status = VerificationStatusList[i];
                    break;
                }
            }
        }

        if (status) {
            return (<span><img src={status.key === VerificationStatus.APPROVED ? IMAGES.IconChecked : IMAGES.IconUnavailable} className="icon-status mx-2" alt="" />{status.title}</span>);
        }

        return '';
    };

    const getRefIds = (cus) => {
        let result = '';

        if (cus.referenceCodes && cus.referenceCodes.length > 0) {
            for (let i = 0; i < cus.referenceCodes.length; i++) {
                if (isAdmin) {
                    if (result) {
                        result = `${result}, ${cus.referenceCodes[i].refId}`;
                    } else {
                        result = cus.referenceCodes[i].refId;
                    }
                } else {
                    if (cus.referenceCodes[i].vendorId === userLogged.vendorId) {
                        result = cus.referenceCodes[i].refId;
                        break;
                    }
                }
            }
        }

        return result ? `[${result}]` : '-';
    };

    const isErrorSentVietPOM = (item) => {
        return item && item.verification === VerificationStatus.APPROVED && (!item.isSentVietPOM || item.pomId == null || item.pomId === '');
    };

    const getErrorFromVietPOM = (item) => {
        let errorMessage = TextApp.getText(TextKey.failing_sending_data_to_vietpom);
        let errorMessageFromVietPOM = '';

        // Error from VietPOM
        if (item && item.vietpomResponse && item.vietpomResponse.response.messages && item.vietpomResponse.response.messages.length > 0) {
            for (let i = 0; i < item.vietpomResponse.response.messages.length; i++) {
                errorMessageFromVietPOM += item.vietpomResponse.response.messages[i] + ' ';
            }
        }

        return errorMessageFromVietPOM !== '' ? `${errorMessage} [ ${errorMessageFromVietPOM}]` : errorMessage;
    };

    const getQueryParamsExportCustomer = (isChunk = false) => {
        const queryExport = {};
        // queryExport[QueryParamsKey.TAKE] = MaxItemsPerPage;
        queryExport[QueryParamsKey.KEYWORD] = keyword ? keyword : '';
        queryExport[QueryParamsKey.FILTER_BY] = filterSelected ? filterSelected.key : '';
        queryExport[QueryParamsKey.SORT_BY] = sortBySelected ? sortBySelected.key : '';
        queryExport[QueryParamsKey.FROM_DATE] = filterByDate && filterByDate.fromDate ? filterByDate.fromDate : '';
        queryExport[QueryParamsKey.TO_DATE] = filterByDate && filterByDate.toDate ? filterByDate.toDate : '';
        queryExport[QueryParamsKey.CHUNK_FILES] = isChunk;

        return queryExport;
    };

    const getExportCustomersURL = () => {
        const queryExport = getQueryParamsExportCustomer(true);

        return ReportService.exportCustomersToFile(queryExport);
    };

    const onClickExportCustomers = async (exportURL) => {
        try {
            exportPage.current.showDetailsModal(getQueryParamsExportCustomer());
            // window.open(url, "_blank", "noreferrer");
        } catch (error) {
            setLoading(false);

            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    useEffect(() => {
        // Define filter customers;
        let filters = [];
        filters = filters.concat(FilterCustomers);
        filters = filters.concat(FilterCustomerRequestKYC);
        filters = filters.concat(FilterByApprovalDate);
        // filters = filters.concat(FilterByCreatedDate);
        filters = filters.concat(FilterCustomersError);

        setFilterCustomers([...filters]);
        // Sort By
        let sortBy = [];
        sortBy = sortBy.concat(SortByDefault);
        sortBy = sortBy.concat(SortByRequestedDateDefault);
        // sortBy = sortBy.concat(SortByApprovedDateDefault);

        setSortByAvailable([...sortBy]);

        // Define menu option status
        let opts = [];
        opts = opts.concat(UserStatusList);

        if (isSuperAdmin) {
            opts = opts.concat(OptionMenuDeleteItem);
        }

        setMenuOptions([...opts]);
        // Get all vendors
        findVendors();
        // Find all customers
        findCustomers();
    }, []);

    /**
     * Show unified customer details
     * @param {*} customerId 
     */
    const showUnifiedCustomer = async (customerId) => {
        try {
            // setLoading(true);
            await CustomersService.getUnifiedCustomerDetails(customerId)
                .then((response) => {
                    // setLoading(false);
                    if (response) {
                        details.current.showDetailsModal(response, vendors, isAdmin);
                    } else {
                        if (response && response.message) {
                            showToastError(response.message);
                        }
                    }
                });
        } catch (error) {
            // setLoading(false);
            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    /**
     * Popup dialog confirm delete the customer
     * @param {*} customer
     */
    const showDialogDelete = (customer) => {
        const title = `Delete Customer: ${customer.name}`;
        const message = `Are you sure you want to delete this customer?`;

        deleteConfirmation.current.showModal(customer, { title: title, message: message });
    }

    /**
     * Menu option
     * @param {*} customer 
     * @param {*} status 
     */
    const chooseOptionStatus = async (customer, status) => {
        try {
            switch (status) {
                case ActionOnRecord.DELETE:
                    showDialogDelete(customer);
                    break;

                default:
                    setLoading(true);
                    await CustomersService.updateCustomerStatus(customer.id, status)
                        .then((response) => {
                            setLoading(false);

                            if (response) {
                                // Reload data
                                findCustomers(keyword, filterSelected, sortBySelected);

                                if (response.message) {
                                    showToastSuccess(response.message);
                                }
                            }
                        });
                    break;

            }
        } catch (error) {
            setLoading(false);

            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    const chooseOptionSyncFailingData = async () => {
        try {
            setLoading(true);
            await CustomersService.syncFaillingData()
                .then((response) => {
                    setLoading(false);

                    if (response) {
                        // Reload data
                        setFilterSelected();
                        setSortBySelected();
                        findCustomers(keyword);

                        if (response.message) {
                            showToastSuccess(response.message);
                        }
                    }
                });
        } catch (error) {
            setLoading(false);

            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    /**
     * Handle delete customer
     * @param {*} customer 
     */
    const handleDeleteCustomer = async (customer) => {
        if (customer == null || customer.id == null || customer.id === '')
            return false;

        try {
            setLoading(true);
            await CustomersService.deteteById(customer.id)
                .then((response) => {
                    setLoading(false);

                    if (response) {
                        // Reload data
                        findCustomers(keyword, filterSelected, sortBySelected);

                        if (response.message) {
                            showToastSuccess(response.message);
                        }
                    }
                });
        } catch (error) {
            setLoading(false);

            if (error && error.message) {
                showToastError(error.message);
            }
        }
    }

    return (
        <>
            <MainPagetitle mainTitle="Customer Management" pageTitle={'List'} parentTitle={'Home'} />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card p-2">
                            <div className="card-body p-0">
                                <div className="col-xl-12 mb-3 mt-1">
                                    <span className='col-xl-4 d-inline-block'>
                                        <div className="input-group search-area">
                                            <span className="input-group-text rounded-0">
                                                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="8.78605" cy="8.78605" r="8.23951" stroke="grey" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M14.5168 14.9447L17.7471 18.1667" stroke="grey" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </span>
                                            <input type="text" className="form-control rounded-0" placeholder="Search customer . . ." onKeyDown={onSearchByKey} onChange={(e) => setKeyword(e.target.value)} />
                                        </div>
                                    </span>
                                    <div className='col-xl-8 d-inline-block text-end'>
                                        <span className='fs-13'>Filter</span>
                                        <span className='d-inline-block ms-2'>
                                            <Dropdown className="task-dropdown-2">
                                                <Dropdown.Toggle as="div">{filterSelected ? filterSelected.title : 'Filter'}</Dropdown.Toggle>
                                                <Dropdown.Menu className='task-drop-menu'>
                                                    {filterCustomers.map((itemFilter, index) => (
                                                        <Dropdown.Item onClick={() => filterAction(itemFilter)} key={index} className={itemFilter.color != null && itemFilter.color !== '' ? itemFilter.color : ''}>{itemFilter.title}</Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </span>
                                        <span className='fs-13 ms-3'>Sort by</span>
                                        <span className='d-inline-block ms-2'>
                                            <Dropdown className="task-dropdown-2">
                                                <Dropdown.Toggle as="div">{sortBySelected ? sortBySelected.title : 'Sort by'}</Dropdown.Toggle>
                                                <Dropdown.Menu className='task-drop-menu'>
                                                    {sortByAvailable.map((itemSortBy, index) => (
                                                        <Dropdown.Item onClick={() => sortByAction(itemSortBy)} key={index}>{itemSortBy.title}</Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </span>
                                        <div className="d-inline-block ms-3">
                                            <span className='d-inline-block fs-13'>From<i className='label-tini'><br />({filterSelected && filterSelected.key === FilterKeys.BY_APPROVAL_DATE ? 'Approval date' : 'Created date'})</i></span>
                                            <span className='d-inline-block ms-2'>
                                                <input type="date" className="form-control d-inline-block form-filter-small" id="val-from-date" name="fromDate" value={filterByDate.fromDate ? filterByDate.fromDate : ''} max={getMaxDateFilter()} onChange={onChangeFilterFromDate} placeholder="" />
                                            </span>
                                            <span className='d-inline-block fs-13 ms-3'>To<i className='label-tini'><br />({filterSelected && filterSelected.key === FilterKeys.BY_APPROVAL_DATE ? 'Approval date' : 'Created date'})</i></span>
                                            <span className='d-inline-block ms-2'>
                                                <input type="date" className="form-control d-inline-block form-filter-small" id="val-to-date" name="toDate" value={filterByDate.toDate ? filterByDate.toDate : ''} max={getMaxDateFilter()} onChange={onChangeFilterToDate} placeholder="" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 mb-3 text-end">
                                    <Link to={"/add-customer"} className="btn btn-primary btn-sm ms-3">+ Add Customer</Link>
                                    <Link to={"/import-customers"} className="btn btn-primary btn-sm ms-2">+ Import Customers</Link>
                                    <Link to={"/update-customers"} className="btn btn-danger btn-sm ms-2"><i className="fa fa-save"></i> Update Customers</Link>
                                    {customers && customers.length > 0 && <>
                                        {/* <a href={getExportCustomersURL()} target='blank' className="btn btn-info btn-sm ms-2"><i className="fa fa-download"></i> Export Customers</a> */}
                                        <button className="btn btn-info btn-sm ms-2" onClick={(e) => {
                                            e.preventDefault();
                                            onClickExportCustomers(getExportCustomersURL());
                                        }}><i className="fa fa-download"></i> Export Customers</button>
                                    </>}
                                    {isEnableOptionSyncFailingData() && <><Dropdown className="dropdown custom-dropdown mb-0 ms-sm-2">
                                        <Dropdown.Toggle
                                            className="btn sharp btn-primary tp-btn i-false"
                                            data-toggle="dropdown">{SVGICON.MenuDot}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                                            {OptionMenuSyncFailingDataItem.map((st, indexStatus) => (<Dropdown.Item key={indexStatus} className={`dropdown-item ${st.color}`} onClick={(e) => {
                                                e.preventDefault();
                                                chooseOptionSyncFailingData();
                                            }}>{st.title}</Dropdown.Item>))}
                                        </Dropdown.Menu>
                                    </Dropdown></>}
                                </div>
                                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                    <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                                        <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>UEN</th>
                                                    <th>Status</th>
                                                    <th>Ref. ID</th>
                                                    <th>Email</th>
                                                    <th>Contact</th>
                                                    <th>Company Reg No.</th>
                                                    <th>Tax ID</th>
                                                    <th>Verification</th>
                                                    {/* <th>Approved By</th> */}
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {customers && customers.map((item, index) => (
                                                    <tr key={index}>
                                                        <td><span>{item.requestVerifyInfoId != null && item.requestVerifyInfoId !== '' ? <><img src={IMAGES.IconProfileWarning} className="icon-profile-warning" alt="" onClick={() => showToastWarning(TextApp.getText(TextKey.request_to_verify_customer_info))} /> </> : ''}{index + 1}</span></td>
                                                        <td><span onClick={() => showUnifiedCustomer(item.id)}>
                                                            <span className='text-black auto-break-word'>{item.name}</span>
                                                            {item.pomId ? (<><br /><span>POM Id: {item.pomId}</span></>) : ''}
                                                        </span>
                                                        </td>
                                                        <td><span className='text-black' onClick={() => showUnifiedCustomer(item.id)}>{item.ucdCode ? item.ucdCode : '-'}</span></td>
                                                        <td><span>{getStatusLabel(item)}</span></td>
                                                        <td>
                                                            <span>{getRefIds(item)}</span>
                                                            {isErrorSentVietPOM(item) && <><span onClick={() => showToastError(getErrorFromVietPOM(item))}><i className="fa fa-exclamation-triangle text-danger ms-1"></i></span></>}
                                                        </td>
                                                        <td><span className="text-info">{item.email ? item.email : '-'}</span></td>
                                                        <td><span>{item.phoneNumber}</span></td>
                                                        <td><span>{item.companyRegNo ? item.companyRegNo : '-'}</span></td>
                                                        <td><span>{item.taxId}</span></td>
                                                        <td><span>{getVerifyLabel(item)}</span></td>
                                                        {/* <td><span>{item.approvedBy ? item.approvedBy : '-'}</span></td> */}
                                                        {/* <span className='text-info'><br />{item.approvedBy && item.approvedByInfo && item.approvedByInfo.name ? `(${item.approvedByInfo.name})` : ''}</span> */}
                                                        <td>
                                                            <Link to={{
                                                                pathname: "/edit-customer",
                                                                search: `?${QueryParamsKey.CUSTOMER_ID}=${item.id}`,
                                                                state: { fromDashboard: true }
                                                            }}><img src={IMAGES.IconEdit} className="avatar avatar-sm" alt="" /></Link>
                                                            <label onClick={() => showUnifiedCustomer(item.id)}><img src={IMAGES.IconView} className="avatar avatar-sm mx-2" alt="" /></label>
                                                            <Dropdown className="dropdown custom-dropdown mb-0">
                                                                <Dropdown.Toggle
                                                                    className="btn sharp btn-primary tp-btn i-false"
                                                                    data-toggle="dropdown">{SVGICON.MenuDot}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                                                                    {menuOptions.map((st, indexStatus) => (<Dropdown.Item key={indexStatus} className={`dropdown-item ${st.color}`} onClick={(e) => {
                                                                        e.preventDefault();
                                                                        chooseOptionStatus(item, st.key);
                                                                    }}>{st.title}</Dropdown.Item>))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {customers && customers.length > 0 ? <>
                                            <div className="d-sm-flex text-center justify-content-between align-items-center">
                                                <div className='dataTables_info'>
                                                    Showing {customers ? customers.length : 0} of {paging && paging.total ? paging.total : 0} entries
                                                </div>
                                                {paging && paging.pageCount && <>
                                                    <div className="dataTables_paginate paging_simple_numbers justify-content-center" id="example2_paginate">
                                                        {currentPage !== 1 && <>
                                                            <Link className="paginate_button previous disabled" to="#" onClick={prePage}>
                                                                <i className="fa-solid fa-angle-left" />
                                                            </Link>
                                                        </>}
                                                        <span>{renderPageIndex(paging.pageCount)}</span>
                                                        {currentPage !== paging.pageCount && <>
                                                            <Link className="paginate_button next" to="#" onClick={nextPage}>
                                                                <i className="fa-solid fa-angle-right" />
                                                            </Link>
                                                        </>}
                                                    </div>
                                                </>}
                                            </div>
                                        </> : <><label className='m-5'>No data</label></>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {loading && (<div className="dialog-blur">{<Preloader />}</div>)}
            <CustomerDetails ref={details} title="Customer Details" />
            <PopupConfirmation ref={deleteConfirmation} onConfirm={handleDeleteCustomer} />
            <ExportCustomers ref={exportPage} title="Export Customers" />
        </>
    );
};

export default Customers;