import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Card, Col, Nav, Offcanvas, Row, Tab } from 'react-bootstrap';
import {
    AllTabsSubUser, CountryCodes, CustomerTabKeys, RoleOfSubUserDefault
} from '../../constant/AppConstant';
import {
    makeEmailFormatHint, makeMobileNumberFormatHint, reFormatPhoneNumber, showToastWarning,
    validateEmail, validatePhoneNumber
} from '../../../helper/helper';
import { SubUserModel } from '../../models/sub-user-model';
import { TextApp, TextKey } from '../../../assets/text-app';

// props: {
//     onSave: function
// }
const AddSubUser = forwardRef((props, ref) => {
    const [display, setDisplay] = useState(false);
    const [roles, setRoles] = useState(RoleOfSubUserDefault.ListAvailable());
    let errorObj = new SubUserModel();
    const [accountModel, setAccountModel] = useState(new SubUserModel());
    const [countryCode, setCountryCode] = useState(CountryCodes.VIETNAM_COUNTRY_CODE);
    const [errors, setErrors] = useState(errorObj);
    const [refreshForm, setRefreshForm] = useState(false);

    const closeToggle = () => {
        setDisplay(false);
    }

    useImperativeHandle(ref, () => ({
        showDetailsModal(data, countryCode) {
            setAccountModel(data);
            setCountryCode(countryCode);
            setDisplay(true);
        }
    }));

    const handleInputProfile = (field, value) => {
        accountModel[field] = value;
        setAccountModel(accountModel);
        setRefreshForm(!refreshForm);
        // Validate form
        // SubUserModel.validate(accountModel, errorObj);
        // setErrors({ ...errorObj });
    }

    const handleInputProfileNoSpace = (field, value) => {
        validateNoSpace(value);
        accountModel[field] = value.replace(/\s+/g, "");
        setAccountModel(accountModel);
        setRefreshForm(!refreshForm);
        // // Validate form
        // SubUserModel.validate(accountModel, errorObj);
        // setErrors({ ...errorObj });
    }

    const handleChooseRole = (value) => {
        accountModel.role = value;
        setAccountModel(accountModel);
        setRoles([...roles]);
    }

    const validateNoSpace = (fieldValue) => {
        const tmpStr = fieldValue.split(' ');

        if (tmpStr && tmpStr.length > 1) {
            showToastWarning(TextApp.getText(TextKey.not_contain_spaces));
        }
    }

    const getPhoneNumberFormatHint = () => {
        return makeMobileNumberFormatHint(countryCode ? countryCode : CountryCodes.VIETNAM_COUNTRY_CODE);
    }

    const saveUser = () => {
        // Validate form
        const validate = SubUserModel.validate(accountModel, errorObj);
        // Validate format phone number
        const validatePhoneAndEmail = validatePhoneNumber(accountModel.phoneNumber, countryCode, countryCode === CountryCodes.VIETNAM_COUNTRY_CODE);

        if (!validatePhoneAndEmail) {
            errorObj.phoneNumber = TextApp.getText(TextKey.phone_number_invalid);
        }

        // Validate format email
        const validateEmailFormat = accountModel.email != null && accountModel.email !== '' ? validateEmail(accountModel.email) : true;

        if (!validateEmailFormat) {
            errorObj.email = TextApp.getText(TextKey.email_invalid);
        }

        if (validate && validatePhoneAndEmail && validateEmailFormat) {
            // Re-format phone number
            accountModel.phoneNumber = reFormatPhoneNumber(accountModel.phoneNumber, countryCode);

            if (props.onSave) {
                props.onSave(accountModel);
            }
            closeToggle();
        }

        setErrors({ ...errorObj });
    }

    return (
        <>
            <Offcanvas show={display} onHide={() => {
                closeToggle();
                setDisplay(false);
            }} className="offcanvas-end customeoff" placement='end'>
                <div className="offcanvas-header">
                    <h5 className="modal-title">{accountModel && accountModel.accountNo ? 'Update User' : 'Add User'}</h5>
                    <button type="button" className="btn-close" onClick={() => {
                        closeToggle();
                    }}><i className="fa-solid fa-xmark"></i></button>
                </div>
                <div className="offcanvas-body">
                    <div className="container-fluid">
                        <Row>
                            <Col xl={12}>
                                <Tab.Container defaultActiveKey="Preview">
                                    <Card name="default-tab" className="dz-card">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="Preview">
                                                <Card.Body>
                                                    {/* <!-- Nav tabs --> */}
                                                    <div className="default-tab">
                                                        <Tab.Container defaultActiveKey={AllTabsSubUser[0].key}>
                                                            <Nav as="ul" className="nav-tabs">
                                                                {AllTabsSubUser.map((data, i) => (
                                                                    <Nav.Item as="li" key={i}>
                                                                        <Nav.Link eventKey={data.key}>
                                                                            <i className={`la la-${data.icon} me-2`} />
                                                                            {data.name}
                                                                        </Nav.Link>
                                                                    </Nav.Item>
                                                                ))}
                                                            </Nav>
                                                            <Tab.Content className="pt-4">
                                                                {AllTabsSubUser.map((data, i) => (
                                                                    <Tab.Pane eventKey={data.key} key={i}>
                                                                        {/* Basic Information */}
                                                                        {data.key === CustomerTabKeys.USER_INFORMATION ? (
                                                                            <div className="form-validation">
                                                                                <div className="row">
                                                                                    <div className="col-xl-12">
                                                                                        <div className="form-group mb-2 row">
                                                                                            <label className="col-lg-5 col-form-label text-danger">POM ID</label>
                                                                                            <div className="col-lg-7">
                                                                                                <input type="text" className="form-control bg-disabled" id="val-pom-id" name="val-pom-id" placeholder="Auto generate from VietPOM" value={accountModel && accountModel.pomId ? accountModel.pomId : ''} disabled />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-2 row">
                                                                                            <label className="col-lg-5 col-form-label text-danger">UCD Code</label>
                                                                                            <div className="col-lg-7">
                                                                                                <input type="text" className="form-control bg-disabled" id="val-ucd-code" name="val-ucd-code" placeholder="Auto generate from UCD" value={accountModel && accountModel.customerNo ? accountModel.customerNo : ''} disabled />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-2 row">
                                                                                            <label className="col-lg-5 col-form-label">Account No</label>
                                                                                            <div className="col-lg-7">
                                                                                                <input type="text" className="form-control bg-disabled" id="val-account-no" name="val-account-no" placeholder="Auto generate from VietPOM" value={accountModel && accountModel.accountNo ? accountModel.accountNo : ''} disabled />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-2 row">
                                                                                            <label className="col-lg-5 col-form-label">Name<span className="text-danger">*</span></label>
                                                                                            <div className="col-lg-7">
                                                                                                <input type="text" className="form-control" id="val-name" name="val-name" placeholder="" value={accountModel.name} onChange={(e) => handleInputProfile('name', e.target.value)} />
                                                                                                {errors.name && <div className="text-danger fs-12">{errors.name}</div>}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-2 row">
                                                                                            <label className="col-lg-5 col-form-label">Phone<span className="text-danger">*</span></label>
                                                                                            <div className="col-lg-7">
                                                                                                <input type="tel" className="form-control" id="val-phone" name="val-phone" placeholder={getPhoneNumberFormatHint()} value={accountModel.phoneNumber} onChange={(e) => handleInputProfileNoSpace('phoneNumber', e.target.value)} />
                                                                                                {errors.phoneNumber && <div className="text-danger fs-12">{errors.phoneNumber}</div>}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-2 row">
                                                                                            <label className="col-lg-5 col-form-label">Email</label>
                                                                                            <div className="col-lg-7">
                                                                                                <input type="email" className="form-control" id="val-email" name="val-email" placeholder={makeEmailFormatHint()} value={accountModel.email} onChange={(e) => handleInputProfile('email', e.target.value)} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-2 row">
                                                                                            <label className="col-lg-5 col-form-label">Fax</label>
                                                                                            <div className="col-lg-7">
                                                                                                <input type="text" className="form-control" id="val-fax" name="val-fax" placeholder="" value={accountModel.fax} onChange={(e) => handleInputProfile('fax', e.target.value)} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="form-group mb-2 row">
                                                                                            <label className="col-lg-5 col-form-label">Role<span className="text-danger">*</span></label>
                                                                                            <div className="col-lg-7">
                                                                                                <select className="form-control" id="val-role" name="val-role" value={accountModel.role} onChange={(e) => handleChooseRole(e.target.value)} required>
                                                                                                    <option value="">Choose role</option>
                                                                                                    {roles.map((role, i) => { return (<option value={role.keyword} key={i}>{role.name}</option>); })}
                                                                                                </select>
                                                                                                {errors.role && <div className="text-danger fs-12">{errors.role}</div>}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ) : ''}
                                                                    </Tab.Pane>
                                                                ))}
                                                            </Tab.Content>
                                                        </Tab.Container>
                                                    </div>
                                                </Card.Body>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Card>
                                </Tab.Container>
                            </Col>
                        </Row>
                        <button type="button" className="btn-info light btn-sm" onClick={() => {
                            saveUser();
                        }}>{accountModel && accountModel.accountNo ? 'Update User' : 'Create User'}</button>
                    </div>

                </div >
            </Offcanvas >
        </>
    );
});

export default AddSubUser;