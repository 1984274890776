/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MainPagetitle from '../../layouts/MainPagetitle';
import { Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import {
    VendorTabKeys, UserStatusList, AllTabsVendorDetails, QueryParamsKey, ACCEPT_ONLY_IMAGE_FILE, SpecialVendors
} from '../../constant/AppConstant';
import { CustomerInfoModel } from '../../models/customer-info-model';
import { showToastError, showToastSuccess, showToastWarning } from '../../../helper/helper';
import VendorsService from '../../../services/VendorsService';
import Preloader from '../../layouts/Preloader';
import { VendorFormDataRequest, VendorInfoModel } from '../../models/vendor-info-model';
import AdminAccount from './AdminAccount';
import { IMAGES } from '../../constant/theme';
import AssetsService from '../../../services/AssetsService';
import { VietPOMCallbackEndpointDefault, VietPOMSubUsersCallbackEndpointDefault } from '../../../services/ApiService';
import UsersService from '../../../services/UsersService';
import { AdminAccountModel } from '../../models/admin-info-model';
import PopupConfirmation from '../../../helper/popup-confirmation';
import { isRoleSuperAdmin } from '../../../services/AuthService';
import { TextApp, TextKey } from '../../../assets/text-app';

const UpdateVendor = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const initialized = useRef(false);
    const isSuperAdmin = isRoleSuperAdmin();
    const [loading, setLoading] = useState(false);
    const deleteConfirmation = useRef();
    // Form
    let errorObj = new CustomerInfoModel();
    const [errors, setErrors] = useState(errorObj);
    const [editVendor, setEditVendor] = useState(null);
    const [vendorFormProfile, setVendorFormProfile] = useState(new VendorInfoModel(VietPOMCallbackEndpointDefault, VietPOMSubUsersCallbackEndpointDefault));
    const [adminAccounts, setAdminAccounts] = useState([]);
    const [newAdminAccounts, setNewAdminAccounts] = useState([]);
    const [logoFile, setLogoFile] = useState();
    const [refreshStatus, setRefreshStatus] = useState(false);
    const [listVendorStatus, setListVendorStatus] = useState(UserStatusList);

    const generateKey = (pre) => {
        return pre ? `${pre}_${new Date().getTime()}` : new Date().getTime();
    }

    const handleInputFormProfile = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;

        setVendorFormProfile((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue
        }));
    }

    const handleInputFormProfileNoSpace = (e) => {
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        validateNoSpace(fieldValue);

        setVendorFormProfile((prevState) => ({
            ...prevState,
            [fieldName]: fieldValue.replace(/\s+/g, "")
        }));
    }

    const handleChooseLogoFile = (e) => {
        setLogoFile(e.target.files[0]);
    }

    const validateNoSpace = (fieldValue) => {
        const tmpStr = fieldValue.split(' ');

        if (tmpStr && tmpStr.length > 1) {
            showToastWarning(TextApp.getText(TextKey.not_contain_spaces));
        }
    }

    const onHandleDeleteAccount = (index) => {
        if (newAdminAccounts.length > index) {
            newAdminAccounts.splice(index, 1);

            setNewAdminAccounts([...newAdminAccounts]);
        }
    };

    const chooseVendorStatus = (value) => {
        vendorFormProfile.status = value;
        setListVendorStatus([...UserStatusList]);
    };

    const onAddNewAdminAccount = () => {
        const newAdmin = new AdminAccountModel();
        newAdmin['id'] = generateKey();

        setNewAdminAccounts([...newAdminAccounts, newAdmin]);
    };

    const onUpdateAdminAccount = (index, data) => {
        const indexInList = index - adminAccounts.length;

        if (newAdminAccounts.length > indexInList && indexInList >= 0) {
            newAdminAccounts[indexInList] = data;

            setNewAdminAccounts([...newAdminAccounts]);
        }
    };

    const isAllowChangeStatus = () => {
        return isSuperAdmin && vendorFormProfile && vendorFormProfile.vendorId !== SpecialVendors.VIETPOM;
    }

    const getLogoURL = () => {
        let fileURL = IMAGES.Avatar;

        if (vendorFormProfile) {
            fileURL = vendorFormProfile.logo && vendorFormProfile.logo !== '' ? vendorFormProfile.logo : IMAGES.Avatar;

            // if (vendorFormProfile.keyFile && vendorFormProfile.keyFile.logoKeyFile) {
            //     fileURL = AssetsService.getFileURL(vendorFormProfile.keyFile.logoKeyFile);
            // }
        }

        return fileURL;
    };

    const getSignedLogoURL = async (vendor) => {
        try {
            if (vendor.keyFile && vendor.keyFile.logoKeyFile) {
                const fileURL = AssetsService.getFileURL(vendor.keyFile.logoKeyFile);

                vendor.logo = await AssetsService.getSignedURL(fileURL);

                if (vendorFormProfile) {
                    vendorFormProfile.logo = vendor.logo;
                }

                setRefreshStatus(!refreshStatus);
            }
        } catch (error) {
            // console.warn(error);
        }
    };

    const isEnableEditEndpointFields = () => {
        return isSuperAdmin;
    };

    useEffect(() => {
        if (initialized.current)
            return;

        initialized.current = true;
        loadData();
    }, [navigate, location]);

    const loadData = async () => {
        const params = new URLSearchParams(location.search)
        const id = params && params.get(QueryParamsKey.ID) ? params.get(QueryParamsKey.ID) : '';

        try {
            setLoading(true);
            await VendorsService.getVendorDetails(id)
                .then((response) => {
                    setLoading(false);

                    if (response) {
                        setEditVendor(response);
                        setVendorFormProfile(response);
                        getSignedLogoURL(response);
                    } else {
                        if (response && response.message) {
                            showToastError(response.message);
                        }
                    }
                });
        } catch (error) {
            setLoading(false);

            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    const loadAdminAccounts = async () => {
        if (editVendor == null || editVendor.vendorId == null || editVendor.vendorId === '')
            return;

        try {
            setLoading(true);
            await UsersService.getUsersByVendorId(editVendor.vendorId)
                .then((response) => {
                    setLoading(false);

                    if (response) {
                        setAdminAccounts(response);
                    } else {
                        if (response && response.message) {
                            showToastError(response.message);
                        }
                    }
                });
        } catch (error) {
            setLoading(false);

            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    /**
    * Dialog confirm delete
    * @param {*} index 
    */
    const showDialogDelete = (index) => {
        let user = null;

        if (adminAccounts.length > index) {
            user = adminAccounts[index];
        }

        if (user == null || user.id == null || user.id === '')
            return false;

        const title = `Delete Account: ${user.name}`;
        const message = `Are you sure you want to delete this account?`;

        deleteConfirmation.current.showModal(index, { title: title, message: message });
    }

    const requestDeleteAccount = async (index) => {
        try {
            let user = null;

            if (adminAccounts.length > index) {
                user = adminAccounts[index];
            }

            if (user == null || user.id == null || user.id === '')
                return false;

            setLoading(true);
            await UsersService.deteteUserById(user.id)
                .then((response) => {
                    setLoading(false);

                    if (response) {
                        adminAccounts.splice(index, 1);
                        setAdminAccounts([...adminAccounts]);
                    } else {
                        showToastError(TextApp.getText(TextKey.delete_failed));
                    }
                });
        } catch (error) {
            setLoading(false);

            if (error && error.message) {
                showToastError(error.message);
            } else {
                showToastError(TextApp.getText(TextKey.delete_failed));
            }
        }
    }

    /**
     * Handle submit all information to UCD
     * @param {*} e 
     * @returns 
     */
    const handleSubmitInfo = (e) => {
        e.preventDefault();

        // Validate data
        if (!validateData()) {
            showToastWarning(TextApp.getText(TextKey.requied_fields));
            return;
        }

        let vendorInfo = new VendorInfoModel();
        vendorInfo.id = editVendor.id;

        // Form data
        const data = new FormData();

        vendorInfo = vendorFormProfile;
        // Admin Accounts
        vendorInfo.adminAccounts = [];
        if (newAdminAccounts.length > 0) {
            vendorInfo.adminAccounts = newAdminAccounts;
        }

        // Data submit
        data.append(VendorFormDataRequest.LOGO_KEY, logoFile);
        data.append(VendorFormDataRequest.DATA_KEY, JSON.stringify(vendorInfo));

        // Send data to UCD
        requestUpdateVendorInfo(data);
    };

    /**
     * Validate form
     * @returns
     */
    const validateData = () => {
        const error = { ...errorObj };
        // Profile
        const resultValidateProfile = VendorInfoModel.validate(vendorFormProfile, error);
        let validateAdminAcc = true;

        // Admin accounts
        if (newAdminAccounts.length > 0) {
            for (let i = 0; i < newAdminAccounts.length; i++) {
                let errorAdminAcc = new AdminAccountModel();
                validateAdminAcc = AdminAccountModel.validate(newAdminAccounts[i], errorAdminAcc);

                if (!validateAdminAcc) {
                    // showToastWarning(`Missing required fields`);
                    validateAdminAcc = false;
                    break;
                }
            }
        }

        setErrors(error);

        return resultValidateProfile && validateAdminAcc;
    }

    /**
    * Send request update vendor info
    * @param {} vendorInfo 
    */
    const requestUpdateVendorInfo = async (vendorInfo) => {
        try {
            setLoading(true);

            // Add new vendor
            await VendorsService.updateVendor(editVendor.id, vendorInfo)
                .then((response) => {
                    setLoading(false);

                    if (response && response.success) {
                        showToastSuccess(TextApp.getText(TextKey.vendor_updated_successfully));
                        resetForm();
                        // navigate(-1);
                        navigate('/vendors', { replace: true });
                    } else {
                        if (response && response.message) {
                            showToastError(response.message);
                        }
                    }
                });
        } catch (error) {
            setLoading(false);

            if (error && error.message) {
                showToastError(error.message);
            }
        }
    };

    const resetForm = () => {
        let vendorInfo = new VendorInfoModel(VietPOMCallbackEndpointDefault, VietPOMSubUsersCallbackEndpointDefault);

        setVendorFormProfile(vendorInfo);
    };

    const getVietPOMCredentialExample = () => {
        const example = `Ex: {"api_client": "your_client_id", "api_key": "your_api_key", "auth_type": "Basic"}`;

        return example;
    }

    return (
        <>
            <MainPagetitle mainTitle="Vendor Management" pageTitle="Profile" parentTitle="Home" />
            <div className="container-fluid">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-3">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <h4 className="heading mb-0">Edit Vendor</h4>
                            </div>
                            <div className="dataTables_wrapper no-footer">
                                <Row>
                                    <Col xl={12}>
                                        <Tab.Container defaultActiveKey="Preview">
                                            <Card name="default-tab" className="dz-card">
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="Preview">
                                                        <Card.Body>
                                                            {/* <!-- Nav tabs --> */}
                                                            <div className="default-tab">
                                                                <Tab.Container defaultActiveKey={AllTabsVendorDetails.length > 0 ? AllTabsVendorDetails[0].key : ''}>
                                                                    <Nav as="ul" className="nav-tabs">
                                                                        {AllTabsVendorDetails.map((data, i) => (
                                                                            <Nav.Item as="li" key={i}>
                                                                                <Nav.Link eventKey={data.key} onClick={(e) => { if (data.key === VendorTabKeys.ADMIN_ACCOUNTS) { loadAdminAccounts(); } }}>
                                                                                    <i className={`la la-${data.icon} me-2`} />
                                                                                    {data.name}
                                                                                </Nav.Link>
                                                                            </Nav.Item>
                                                                        ))}
                                                                    </Nav>
                                                                    <Tab.Content className="pt-4">
                                                                        {AllTabsVendorDetails.map((data, i) => (
                                                                            <Tab.Pane eventKey={data.key} key={i}>
                                                                                {/* Basic Information */}
                                                                                {data.key === VendorTabKeys.BASIC_INFORMATION ? (
                                                                                    <div className="form-validation">
                                                                                        <div className="row">
                                                                                            <div className="col-xl-6">
                                                                                                <div className="form-group mb-3 row">
                                                                                                    <div className="col-lg-12 dz-default dlab-message upload-img">
                                                                                                        <div className="dropzone">
                                                                                                            <div className="c-profile text-center">
                                                                                                                <img src={logoFile ? URL.createObjectURL(logoFile) : getLogoURL()} alt="" className="rounded-circle logo-vendor mb-2" />
                                                                                                            </div>
                                                                                                            <input type="file" id="val-logo-image" name="logoFile" onChange={handleChooseLogoFile} accept={ACCEPT_ONLY_IMAGE_FILE} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="form-group mb-3 row">
                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-company-name">Vendor ID<span className="text-danger">*</span></label>
                                                                                                    <div className="col-lg-8">
                                                                                                        <input type="text" className="form-control bg-disabled" id="vendor-id" name="vendorId" value={vendorFormProfile.vendorId} onChange={handleInputFormProfile} placeholder="" disabled />
                                                                                                        {errors.vendorId && <div className="text-danger fs-12">{errors.vendorId}</div>}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="form-group mb-3 row">
                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-company-name">Company Name<span className="text-danger">*</span></label>
                                                                                                    <div className="col-lg-8">
                                                                                                        <input type="text" className="form-control" id="company-name" name="companyName" value={vendorFormProfile.companyName} onChange={handleInputFormProfile} placeholder="" required />
                                                                                                        {errors.companyName && <div className="text-danger fs-12">{errors.companyName}</div>}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="form-group mb-3 row">
                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-business-reg-no">Business Reg No.<span className="text-danger">*</span></label>
                                                                                                    <div className="col-lg-8">
                                                                                                        <input type="text" className="form-control" id="business-reg-no" name="businessRegNo" value={vendorFormProfile.businessRegNo} onChange={handleInputFormProfileNoSpace} placeholder="" required />
                                                                                                        {errors.businessRegNo && <div className="text-danger fs-12">{errors.businessRegNo}</div>}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="form-group mb-3 row">
                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-phone">Contact<span className="text-danger">*</span></label>
                                                                                                    <div className="col-lg-8">
                                                                                                        <input type="tel" className="form-control" id="phone-number" name="contact" value={vendorFormProfile.contact} onChange={handleInputFormProfileNoSpace} placeholder="" required />
                                                                                                        {errors.contact && <div className="text-danger fs-12">{errors.contact}</div>}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="form-group mb-3 row">
                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-email">Email<span className="text-danger">*</span></label>
                                                                                                    <div className="col-lg-8">
                                                                                                        <input type="email" className="form-control" id="email" name="email" value={vendorFormProfile.email} onChange={handleInputFormProfile} placeholder="" required />
                                                                                                        {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="form-group mb-3 row">
                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="profile-address">Address<span className="text-danger">*</span></label>
                                                                                                    <div className="col-lg-8">
                                                                                                        <input type="text" className="form-control" id="profile-address" name="address" value={vendorFormProfile.address} onChange={handleInputFormProfile} placeholder="" required />
                                                                                                        {errors.address && <div className="text-danger fs-12">{errors.address}</div>}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="form-group mb-3 row">
                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-status">Status<span className="text-danger">*</span></label>
                                                                                                    <div className="col-lg-8">
                                                                                                        <select className="form-control mb-3" id="val-vendor-status" name="val-vendor-status" value={vendorFormProfile.status} onChange={(e) => chooseVendorStatus(e.target.value)}>
                                                                                                            {listVendorStatus.map((s, i) => {
                                                                                                                return (<option value={s.key} key={i}>{s.title}</option>);
                                                                                                            })}
                                                                                                        </select>
                                                                                                        {isAllowChangeStatus() && <>
                                                                                                            <span><input type="checkbox" id="allow-create-account" value={vendorFormProfile.allowCreateAccount} checked={vendorFormProfile.allowCreateAccount} onChange={() => { vendorFormProfile.allowCreateAccount = !vendorFormProfile.allowCreateAccount; setRefreshStatus(!refreshStatus); }} className="me-2" /><label htmlFor="allow-create-account">Allow Create Vendor Admin</label></span>
                                                                                                        </>}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-xl-6">
                                                                                                <div className="form-group mb-3 row">
                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-vendor-auth-callback-endpoint">Auth Callback Endpoint<span className='text-muted fs-12'><br />(HTTP POST)</span></label>
                                                                                                    <div className="col-lg-8">
                                                                                                        <div className='mb-2'>
                                                                                                            <input type="text" className={`form-control ${isEnableEditEndpointFields() ? '' : 'bg-disabled'}`} id="val-vendor-auth-callback-endpoint" name="vendorAuthCallbackEndpoint" value={vendorFormProfile.vendorAuthCallbackEndpoint} onChange={handleInputFormProfileNoSpace} placeholder="https://api.vendor/auth" disabled={!isEnableEditEndpointFields()} />
                                                                                                            {errors.vendorAuthCallbackEndpoint && <div className="text-danger fs-12">{errors.vendorAuthCallbackEndpoint}</div>}
                                                                                                        </div>
                                                                                                        <label className="col-form-label" htmlFor="vendor-credential">Credential Payload (JSON format)</label>
                                                                                                        <textarea className={`form-txtarea form-control textarea-resize ${isEnableEditEndpointFields() ? '' : 'bg-disabled'}`} rows="5" id="vendor-credential" name="vendorCredential" value={vendorFormProfile.vendorCredential != null ? vendorFormProfile.vendorCredential : ''} onChange={handleInputFormProfile} disabled={!isEnableEditEndpointFields()}></textarea>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="form-group mb-3 row">
                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-vendor-identity-callback-endpoint">Identity Callback Endpoint<span className='text-muted fs-12'><br />(HTTP GET)</span></label>
                                                                                                    <div className="col-lg-8">
                                                                                                        <input type="text" className={`form-control ${isEnableEditEndpointFields() ? '' : 'bg-disabled'}`} id="val-vendor-identity-callback-endpoint" name="vendorIdentityCallbackEndpoint" value={vendorFormProfile.vendorIdentityCallbackEndpoint} onChange={handleInputFormProfileNoSpace} placeholder="https://api.vendor/identity?email=%s&phone=%s&code=%s" disabled={!isEnableEditEndpointFields()} />
                                                                                                        <span className='text-muted fs-12'><i>Authorization: Bearer [access_token]</i></span>
                                                                                                        {errors.vendorIdentityCallbackEndpoint && <div className="text-danger fs-12">{errors.vendorIdentityCallbackEndpoint}</div>}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="form-group mb-3 row">
                                                                                                    <label className="col-lg-4 col-form-label" htmlFor="val-vietpom-callback-endpoint">VietPOM Callback Endpoint<span className="text-danger">*</span><span className='text-muted fs-12'><br />(HTTP POST)</span></label>
                                                                                                    <div className="col-lg-8">
                                                                                                        <div className='mb-2'>
                                                                                                            <input type="text" className={`form-control ${isEnableEditEndpointFields() ? '' : 'bg-disabled'}`} id="val-vietpom-callback-endpoint" name="vietpomCallbackEndpoint" value={vendorFormProfile.vietpomCallbackEndpoint} onChange={handleInputFormProfileNoSpace} placeholder="https://api.vietpom/1.0/ext_customers" disabled={!isEnableEditEndpointFields()} />
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <label className="col-form-label" htmlFor="val-vietpom-sub-users-callback-endpoint">Manage sub-users</label>
                                                                                                            <input type="text" className={`form-control ${isEnableEditEndpointFields() ? '' : 'bg-disabled'}`} id="val-vietpom-sub-users-callback-endpoint" name="vietpomSubUsersCallbackEndpoint" value={vendorFormProfile.vietpomSubUsersCallbackEndpoint} onChange={handleInputFormProfileNoSpace} placeholder="https://api.vietpom/1.0/ext_sub_users" disabled={!isEnableEditEndpointFields()} />
                                                                                                        </div>
                                                                                                        <label className="col-form-label" htmlFor="vietpom-credential">Credential Payload (JSON format)</label>
                                                                                                        <textarea className={`form-txtarea form-control textarea-resize ${isEnableEditEndpointFields() ? '' : 'bg-disabled'}`} rows="5" id="vietpom-credential" name="vietpomCredential" value={vendorFormProfile.vietpomCredential != null ? vendorFormProfile.vietpomCredential : ''} onChange={handleInputFormProfile} disabled={!isEnableEditEndpointFields()}></textarea>
                                                                                                        <span className='text-muted fs-12'><i>{getVietPOMCredentialExample()}</i></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ) : ''}
                                                                                {/* Admin Accounts */}
                                                                                {data.key === VendorTabKeys.ADMIN_ACCOUNTS ? (
                                                                                    <div className="form-validation">
                                                                                        <div className="row">
                                                                                            {adminAccounts && adminAccounts.map((acc, i) => {
                                                                                                return (<AdminAccount key={acc.id} index={i} data={acc} onDelete={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    showDialogDelete(i);
                                                                                                }} />);
                                                                                            })}
                                                                                            {newAdminAccounts && newAdminAccounts.map((acc, i) => {
                                                                                                return (<AdminAccount key={acc.id} index={i + adminAccounts.length} onDelete={(e) => {
                                                                                                    e.preventDefault();
                                                                                                    onHandleDeleteAccount(i);
                                                                                                }} onUpdate={onUpdateAdminAccount} data={acc} />);
                                                                                            })}
                                                                                        </div>
                                                                                        {/* Add Admin */}
                                                                                        <button className="btn btn-primary light btn-sm ms-2" onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            onAddNewAdminAccount();
                                                                                        }} key={i}>{`+ Add Admin`}</button>
                                                                                    </div>
                                                                                ) : ''}
                                                                            </Tab.Pane>
                                                                        ))}
                                                                    </Tab.Content>
                                                                </Tab.Container>
                                                            </div>
                                                        </Card.Body>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Card>
                                        </Tab.Container>
                                    </Col>
                                </Row>
                                {isSuperAdmin && <>
                                    <div className="form-group row">
                                        <div className="col-xl-12 text-end">
                                            <button className="btn btn-primary" onClick={handleSubmitInfo}>Update Vendor</button>
                                        </div>
                                    </div></>}
                            </div>
                        </div>
                    </div>
                </div>
                {loading && (<div className="dialog-blur">{<Preloader />}</div>)}
                <PopupConfirmation ref={deleteConfirmation} onConfirm={requestDeleteAccount} />
            </div >
        </>
    )
}

export default UpdateVendor;